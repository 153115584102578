import { Box, Button, Stack, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons-react';

import { validateCodeEmbed } from './validateCodeEmbed';

import BasePanel from '../BasePanel/BasePanel';

import { ToolbarPanelProps } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';

const CodeEmbedPanel = ({ opened, handleClickToToggleSubPanel }: ToolbarPanelProps) => {
  const form = useForm({
    initialValues: {
      code: '',
    },
    validateInputOnChange: true,
    validate: {
      code: validateCodeEmbed,
    },
  });

  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('code-embed-panel')}
      label="Code Embed Panel"
      title="Configure Code Embed"
      publishButton={
        <Box p={16} style={{ boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)' }}>
          <Button
            w="100%"
            size="md"
            disabled={!form.isValid()}
            onClick={() => handleClickToToggleSubPanel('code-embed-panel')}
            fw={400}
          >
            Save
          </Button>
        </Box>
      }
      icon={
        <IconArrowLeft
          cursor={'pointer'}
          onClick={() =>
            msg({
              type: 'open-toolbar-panel',
              openToolbarPanel: 'add-element',
              openToolbarSubPanel: 'add-element',
            })
          }
          aria-label='Back to "Add Element" panel'
        />
      }
    >
      <Stack gap={4}>
        <Textarea
          rows={8}
          label="Embed code"
          description="Copy the HTML code snippet you want to add and paste it into the box below"
          styles={{
            input: {
              fontSize: 12,
              color: 'var(--mantine-color-dark-7)',
            },
            description: {
              fontSize: 14,
              color: 'var(--mantine-color-gray-7)',
            },
            label: {
              fontWeight: 600,
              color: 'var(--mantine-color-dark-7)',
            },
          }}
          placeholder="<iframe src=”https://myiframe.com/” .... >"
          {...form.getInputProps('code')}
        />

        <Text fz={12} c="var(--mantine-color-gray-7)">
          Use Preview to check how your embedded code looks after hitting Save.
        </Text>
      </Stack>
    </BasePanel>
  );
};

export default CodeEmbedPanel;
