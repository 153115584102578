import { Button, Divider, Flex, Stack, Text, Textarea, TextInput, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconInfoCircle, IconPlus } from '@tabler/icons-react';

import { validateKeywords, validateName, validateSlug, validateTitle } from './validate';

import { VARIANTS_KEYWORDS_LIMIT } from '~/constants';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

interface DefineVariantProps {
  onClose: () => void;
}

const amountOfKeywords = (keywords: string): number =>
  keywords.length === 0 ? 0 : keywords.split(',').length;

const DefineVariant = ({ onClose }: DefineVariantProps) => {
  const { generateVariants } = usePageSet();
  const form = useForm({
    initialValues: {
      name: '',
      keywords: '',
      slug: '',
      title: '',
    },
    validate: {
      name: validateName,
      keywords: validateKeywords,
      slug: validateSlug,
      title: validateTitle,
    },
    validateInputOnChange: true,
  });

  const handleClickToDefineVariant = () => {
    const { name, keywords, slug, title } = form.values;
    generateVariants(
      name,
      keywords.split(',').map((item) => item.trim()),
      slug,
      title,
    );
    onClose();
  };

  return (
    <Stack gap={24}>
      <Text fz={14} fw={700}>
        Page variants allow you to generate and manage multiple connected pages for different
        locations or personas, using a single blueprint as a template.
      </Text>

      <TextInput
        label={
          <Flex align="center" gap={4} fw={600}>
            Variant type
          </Flex>
        }
        styles={{
          input: {
            fontSize: 14,
            fontWeight: 700,
          },
          error: {
            fontWeight: 700,
          },
          description: {
            fontSize: 12,
            fontWeight: 700,
            color: 'var(--mantine-color-gray-7)',
          },
        }}
        description="Give this variant a clear, descriptive name to easily identify it later"
        {...form.getInputProps('name')}
      />

      <Stack gap={8}>
        <Textarea
          rows={8}
          label={
            <Flex align="center" gap={4} fw={600}>
              Add variant keywords
              <Tooltip
                fz={12}
                withinPortal={false}
                position="top-start"
                withArrow
                label={
                  <>
                    These keywords will be used to generate
                    <br />
                    unique pages based on your variant type
                  </>
                }
              >
                <IconInfoCircle size={16} />
              </Tooltip>
            </Flex>
          }
          styles={{
            input: {
              fontSize: 14,
              fontWeight: 700,
            },
            error: {
              fontWeight: 700,
            },
            description: {
              fontSize: 12,
              fontWeight: 700,
              color: 'var(--mantine-color-gray-7)',
            },
          }}
          placeholder="e.g. New York, San Francisco, Los Angeles"
          description={`Enter up to ${VARIANTS_KEYWORDS_LIMIT} keywords, separated by commas or line breaks,
              that define your page variants`}
          {...form.getInputProps('keywords')}
        />

        {form.isValid('keywords') && (
          <Text fz={12} fw={700} ta="right" c="var(--mantine-color-gray-7)">
            {amountOfKeywords(form.values.keywords)}/{VARIANTS_KEYWORDS_LIMIT} keywords used
          </Text>
        )}
      </Stack>

      <Stack gap={8}>
        <TextInput
          leftSection={<span>/</span>}
          label={
            <Flex align="center" gap={4} fw={600}>
              URL Slug
              <Tooltip
                fz={12}
                withinPortal={false}
                position="top-start"
                withArrow
                label={
                  <>
                    To use the keyword in your URL slug, insert
                    <br />
                    @keyword where you want the text to
                    <br />
                    appear.
                  </>
                }
              >
                <IconInfoCircle size={16} />
              </Tooltip>
            </Flex>
          }
          styles={{
            input: {
              fontSize: 14,
              fontWeight: 700,
            },
            error: {
              fontWeight: 700,
            },
          }}
          placeholder="@keyword"
          {...form.getInputProps('slug')}
          onChange={(event) => {
            const value = event.currentTarget.value.toLowerCase();
            const formattedSlug = value.replace(/\s+/g, '-');
            form.setFieldValue('slug', formattedSlug);
          }}
        />

        {form.isValid('keywords') && (
          <Text fz={12} fw={700} c="var(--mantine-color-gray-7)">
            Use @keyword to insert your keyword in the URL
          </Text>
        )}
      </Stack>

      <Stack gap={8}>
        <TextInput
          label={
            <Flex align="center" gap={4} fw={600}>
              SEO Page Title
              <Tooltip
                fz={12}
                withinPortal={false}
                position="top-start"
                withArrow
                label={
                  <>
                    To use the keyword in your title, insert
                    <br />
                    @keyword where you want the text to
                    <br />
                    appear.
                  </>
                }
              >
                <IconInfoCircle size={16} />
              </Tooltip>
            </Flex>
          }
          styles={{
            input: {
              fontSize: 14,
              fontWeight: 700,
            },
            error: {
              fontWeight: 700,
            },
          }}
          placeholder="@keyword"
          {...form.getInputProps('title')}
        />

        {form.isValid('keywords') && (
          <Text fz={12} fw={700} c="var(--mantine-color-gray-7)">
            Use @keyword to insert your keyword in the page title
          </Text>
        )}
      </Stack>

      <Divider />

      <Flex gap={14} wrap="wrap">
        <Button onClick={onClose} variant="default" type="button" maw="100%">
          Cancel
        </Button>
        <Button
          leftSection={<IconPlus />}
          disabled={!form.isValid()}
          onClick={handleClickToDefineVariant}
          maw="100%"
        >
          Generate variants
        </Button>
      </Flex>
    </Stack>
  );
};

export default DefineVariant;
