import {
  ActionIcon,
  Box,
  Button,
  FileButton,
  Flex,
  ScrollArea,
  Skeleton,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconBrowserCheck, IconPlayerPlay, IconWorldWww } from '@tabler/icons-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import validator from 'validator';

import PublishConfirmationPanel from './PublishConfirmationPanel';

import BasePanel from '../BasePanel/BasePanel';

import { PageJsonSnippetObj } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { uploadFile } from '~/services/AssetServices';
import { publishPage, updatePublishSettings } from '~/services/PageServices';
import { addDomain, getDomain } from '~/services/WorkspaceServices';

interface PublishPanelProps {
  opened: boolean;
  onClickToClose: () => void;
}

const {
  VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME = '*.testing.upflowyexperience.com',
  VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME,
} = import.meta.env || {};

const publish =
  (
    nanoId = '',
    setPublishing: Dispatch<SetStateAction<boolean>>,
    setTab: Dispatch<SetStateAction<'settings' | 'confirmation'>>,
  ) =>
  async () => {
    try {
      await publishPage({ nanoId });
      setTab('confirmation');
      notifications.show({
        color: 'blue',
        message: 'Page published successfully!',
        autoClose: 3000,
      });
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Unable to publish page!',
        autoClose: 3000,
      });
    } finally {
      setPublishing(false);
    }
  };

const addNewDomain = async (
  workspaceId: string,
  domain: string,
  setPublishing: Dispatch<SetStateAction<boolean>>,
  formDomain: UseFormReturnType<
    {
      domain: string;
      cname: string;
      error: boolean;
    },
    (values: { domain: string; cname: string; error: boolean }) => {
      domain: string;
      cname: string;
      error: boolean;
    }
  >,
) => {
  try {
    setPublishing(true);
    const response = await addDomain({ workspaceId: workspaceId ?? '', domain });

    if (response.vercelResponse && !response.vercelResponse?.verified) {
      formDomain.setValues({
        error: true,
      });
      notifications.show({
        color: 'red',
        message: 'This domain name is already registered in Vercel! Please try another one.',
        autoClose: 3000,
      });
      return;
    }
    notifications.show({
      color: 'blue',
      message: 'Add domain successfully! Continue publishing...',
      autoClose: 3000,
    });
    formDomain.setValues({
      cname: response.cnameValue,
      error: false,
    });
  } catch (error) {
    formDomain.setValues({
      error: true,
    });
    notifications.show({
      color: 'red',
      message: 'Unable to add domain, it may already exist!',
      autoClose: 3000,
    });
  }
};

const PublishPanel = ({ opened, onClickToClose }: PublishPanelProps) => {
  const [publishing, setPublishing] = useState(false);
  const [faviconFile, setFaviconFile] = useState<File | null>(null);
  const [loadDomain, setLoadDomain] = useState(true);
  const [tab, setTab] = useState<'settings' | 'confirmation'>('settings');

  const form = useForm({
    initialValues: {
      title: '',
      favicon: '',
      description: '',
      googleTagManagerId: '',
    },
    validateInputOnChange: true,
    validate: {
      googleTagManagerId: (value) => {
        if (!value) return null;

        return validator.matches(value, /^GTM-[A-Z0-9]{1,8}$/i)
          ? null
          : 'Invalid Google Tag Manager ID format, must start with "GTM-"';
      },
    },
  });

  const formDomain = useForm({
    initialValues: {
      domain: '',
      cname: '',
      error: false,
    },
    validateInputOnChange: true,
    validate: {
      domain: (value) => (value.length > 0 && !validator.isFQDN(value) ? 'Invalid domain' : null),
    },
  });

  const formForNotContent = useForm({
    initialValues: {
      nanoId: '',
      workspaceId: '',
      urlSlug: '',
    },
    validateInputOnChange: true,
    validate: {
      urlSlug: (value) => (value.length === 0 || !validator.isSlug(value) ? 'Invalid slug' : null),
    },
  });

  useEffect(() => {
    if (opened) {
      msg({ type: 'editor-sync' });
      msg({ type: 'trigger-broadcast-page-data' });
    }
  }, [opened]);

  useViewerMessage(
    async ({ data }) => {
      if (data.type === 'broadcast-page-data' && opened) {
        const { nanoId, definition: urlSlug, workspaceId = '' } = data.pageDetails;

        const {
          title = '',
          favicon = '',
          head = [],
          googleTagManagerId = '',
          description,
        } = JSON.parse(data.pageData || '{}');

        const legacyDescription =
          (head.find((tag: PageJsonSnippetObj) => tag['@name'] === 'description') || {})[
            '@content'
          ] || '';

        const obj = {
          title,
          favicon,
          googleTagManagerId,
          description: description || legacyDescription,
        };

        const objForNotContent = {
          nanoId,
          workspaceId,
          urlSlug,
        };

        form.setValues(obj);
        form.resetDirty(obj);
        formForNotContent.setValues(objForNotContent);
        formForNotContent.resetDirty(objForNotContent);

        setLoadDomain(true);
        try {
          const getDomainResult = await getDomain({ workspaceId });
          if (getDomainResult.domain) {
            formDomain.setValues(getDomainResult);
            formDomain.resetDirty({
              domain: getDomainResult.domain,
              cname: getDomainResult.cname,
              error: false,
            });
            setLoadDomain(false);
          }
        } catch {
          setLoadDomain(false);
        }
      }

      if (data.type === 'update-page-content-success' && opened && publishing) {
        form.resetDirty(form.values);
        publish(formForNotContent.values.nanoId, setPublishing, setTab)();
      }

      if (data.type === 'update-page-content-failed' && opened && publishing) {
        notifications.show({
          color: 'red',
          message: JSON.parse(data.outputData.errorMessage).message,
          autoClose: 3000,
        });
        setPublishing(false);
      }
    },
    [opened, publishing, form, formForNotContent],
  );

  const handleClickToRunPublishSequence = async () => {
    setPublishing(true);

    if (opened && formDomain.isDirty() && formDomain.values.domain) {
      addNewDomain(
        formForNotContent.values.workspaceId,
        formDomain.values.domain,
        setPublishing,
        formDomain,
      );
      formDomain.resetDirty(formDomain.values);
    }

    if (opened && faviconFile) {
      // If there is a new favicon, form must be dirty
      const { workspaceId, urlSlug } = formForNotContent.values;
      let uploadResponse;
      try {
        msg({ type: 'upload-to-collection', file: faviconFile });
        // The below uploads to assets folder via Asset Handling Service
        uploadResponse = await uploadFile({ workspaceId, file: [faviconFile] });
      } catch {
        notifications.show({
          color: 'red',
          message: 'Something wrong during new favicon uploads. Halting the publish process.',
          autoClose: 3000,
        });
        setPublishing(false);
        return;
      }

      const favicon = `${workspaceId}/${uploadResponse.filePaths[0]}`;

      notifications.show({
        color: 'blue',
        message: 'New favicon uploads successfully! Continue publishing...',
        autoClose: 3000,
      });

      msg({
        type: 'editing-publish-settings',
        outputData: { ...form.values, urlSlug, favicon },
      });
      form.setFieldValue('favicon', favicon);
      setFaviconFile(null);
    } else if (opened && form.isDirty()) {
      const { urlSlug } = formForNotContent.values;

      // If there is no new favicon, but other form is edited
      msg({ type: 'editing-publish-settings', outputData: { ...form.values, urlSlug } });
    } else {
      // If nothing is edited, should still cater publish
      try {
        await updatePublishSettings(formForNotContent.values);
      } catch (error) {
        let message = 'Unable to update page settings!';
        if (error instanceof Error && error.message) {
          message =
            typeof error.message == 'string' ? JSON.parse(error.message).message : error.message;
        }
        notifications.show({
          color: 'red',
          message,
          autoClose: 3000,
        });
        setPublishing(false);
        return;
      }
      publish(formForNotContent.values.nanoId, setPublishing, setTab)();
    }
  };

  const formHasErrors =
    Object.keys(formForNotContent.errors).length + Object.keys(form.errors).length !==
      0 + Object.keys(formDomain.errors).length || formForNotContent.values.urlSlug.length === 0;

  const getUrlDescription = () => {
    if (VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME) {
      return 'URL path not shown in dev environment';
    }

    if (formDomain.values.domain && !formDomain.isDirty()) {
      return `https://${formDomain.values.domain}/${formForNotContent.values.urlSlug}`;
    }

    return `https://${VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME.replace(
      '*',
      formForNotContent.values.workspaceId,
    )}/${formForNotContent.values.urlSlug}`;
  };

  const urlDescription = getUrlDescription();

  return (
    <>
      {tab === 'settings' && (
        <BasePanel
          opened={opened}
          onClickToClose={onClickToClose}
          label="Publish"
          title="Page Settings"
          icon={<IconBrowserCheck />}
          publishButton={
            <Box p={16} style={{ boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)' }}>
              <Button
                w="100%"
                loading={publishing}
                disabled={publishing || formHasErrors}
                loaderProps={{ type: 'dots' }}
                onClick={handleClickToRunPublishSequence}
              >
                Publish
              </Button>
            </Box>
          }
        >
          <ScrollArea style={{ height: 'calc(100vh - 320px)' }} type="never">
            <Title order={6} fw={500}>
              Page title and favicon
            </Title>
            <Flex align="center" gap={8} mt={4} w="100%">
              <FileButton
                onChange={setFaviconFile}
                accept="image/png,image/svg+xml"
                disabled={publishing}
              >
                {(props) => {
                  let src = form.values.favicon || '/logolight-upflowyicon1.svg';
                  const { workspaceId } = formForNotContent.values;

                  if (form.values.favicon.length > 0) {
                    if (VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME) {
                      src = `https://${VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME}/${form.values.favicon}`;
                    } else {
                      src = `https://${VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME.replace(
                        '*',
                        workspaceId,
                      )}/${form.values.favicon}`;
                    }
                  }

                  if (faviconFile) {
                    src = URL.createObjectURL(faviconFile);
                  }

                  return (
                    <ActionIcon
                      {...props}
                      variant="default"
                      aria-label="Upload favicon"
                      size={36}
                      style={{ opacity: publishing ? '.4' : '1' }}
                    >
                      <Box p={4}>
                        <img
                          src={src}
                          width="100%"
                          height="100%"
                          alt="favicon"
                          onError={(e) => {
                            (e.target as HTMLImageElement).src = '/logolight-upflowyicon1.svg';
                          }}
                        />
                      </Box>
                    </ActionIcon>
                  );
                }}
              </FileButton>
              <TextInput
                width="100%"
                aria-label="Set page title"
                placeholder="Title displays on the browser tab"
                size="sm"
                style={{ flex: '1 1 100%' }}
                disabled={publishing}
                {...form.getInputProps('title')}
              />
            </Flex>

            <TextInput
              leftSection={<span>/</span>}
              label="URL slug"
              size="sm"
              mt="md"
              withAsterisk
              disabled={publishing}
              description={urlDescription}
              {...formForNotContent.getInputProps('urlSlug')}
            />

            <Textarea
              placeholder="Description meta tag"
              label="Description"
              size="sm"
              mt="md"
              autosize
              minRows={4}
              disabled={publishing}
              {...form.getInputProps('description')}
            />

            <Flex align="center" mt={36}>
              <ThemeIcon color="#f3eaff">
                <IconWorldWww color="#6d3fee" size="18" stroke={1} />
              </ThemeIcon>
              <Title order={4} ml={8} fw={400}>
                Domain Settings
              </Title>
            </Flex>

            <Skeleton visible={loadDomain} mt={16} height={80}>
              <TextInput
                label="Add a Domain or Subdomain"
                description={
                  formDomain.values.domain && !formDomain.isDirty()
                    ? 'Please contact us for updating the domain'
                    : "Type the domain or subdomain exactly as you'd want it to appear"
                }
                disabled={publishing || (!!formDomain.values.domain && !formDomain.isDirty())}
                placeholder="www.yourcompany.com"
                size="sm"
                mt="md"
                {...formDomain.getInputProps('domain')}
              />
            </Skeleton>
            <Flex align="center" mt={36}>
              <img
                width={24}
                alt="Google Tag Manager"
                src="//www.gstatic.com/analytics-suite/header/suite/v2/ic_tag_manager.svg"
              />
              <Title order={4} ml={8} fw={400}>
                Google Tag Manager
              </Title>
            </Flex>

            <TextInput
              label="Container ID"
              size="sm"
              mt="md"
              placeholder="Tag manager's container ID"
              disabled={publishing}
              {...form.getInputProps('googleTagManagerId')}
            />

            <Button
              variant="default"
              w="100%"
              mt={36}
              onClick={() => window.open('./preview', '_newtab')}
            >
              <IconPlayerPlay />
              <Text ml={8}>Preview page</Text>
            </Button>
          </ScrollArea>
        </BasePanel>
      )}
      {tab === 'confirmation' && (
        <PublishConfirmationPanel
          onClickToClose={onClickToClose}
          formDomain={formDomain}
          setTab={setTab}
          formForNotContent={formForNotContent}
          opened={opened}
        />
      )}
    </>
  );
};

export default PublishPanel;
