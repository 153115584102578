import { Button, Divider, Text } from '@mantine/core';

interface PublishCompletedModalProps {
  onClose: () => void;
  published: Record<string, boolean>;
}
const PublishCompleted = ({ onClose, published }: PublishCompletedModalProps) => {
  const succeeded = Object.values(published).filter((status) => status === true).length;
  const failed = Object.values(published).filter((status) => status === false).length;

  return (
    <>
      <Text mt={0} fz={14}>
        {succeeded} variants were successfully published, while {failed} variants failed to publish.
        {failed > 0 ? ` We will try to republish these variants the next time you publish.` : ''}
      </Text>

      <Divider mt={32} />

      <Button mt={16} onClick={onClose} type="button" miw="15%">
        Ok
      </Button>
    </>
  );
};

export default PublishCompleted;
