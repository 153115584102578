import { Collapse, Modal } from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import {
  IconArrowBarUp,
  IconBrowserCheck,
  IconChevronRight,
  IconConfetti,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import validator from 'validator';

import PublishCompleted from './PublishCompleted';

import PublishingVariantLightbox from '../../VariantMenuExtension/PublishingVariantLightbox/PublishingVariantLightbox';
import BaseSubMenuButton from '../BaseSubMenuButton/BaseSubMenuButton';
import { validateSlug, validateTitle } from '../PublishPanel/validatePublishSettings';
import { SubMenuContainer } from '../Toolbar.styles';
import { publishSubPanelCollection } from '../useToolbarOperations';

import {
  MakeOpenSubPanelObj,
  PageJsonSnippetObj,
  PublishSubPanelTypes,
  SubPanelTypes,
} from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';
import { getDomain } from '~/services/WorkspaceServices';

interface PublishSettingsMenuProps {
  opened: boolean;
  makeOpenSubPanel?: MakeOpenSubPanelObj;
}

export type FormType = UseFormReturnType<{
  title: string;
  favicon: string;
  description: string;
  urlSlug: string;
  googleTagManagerId: string;
}>;

export type FormForNotContentType = UseFormReturnType<{
  nanoId: string;
  workspaceId: string;
  lastPublishedDate?: number;
}>;

export type FormDomainType = UseFormReturnType<{
  domain: string;
  cname: string;
  error: boolean;
}>;

const PublishSettingsMenu = ({ opened, makeOpenSubPanel }: PublishSettingsMenuProps) => {
  const { selectedVariant, published } = usePageSet();
  const [loadDomain, setLoadDomain] = useState(true);
  const [openedSub, setOpenedSub] = useState('');
  const [isCurrentVariantEdited, setIsCurrentVariantEdited] = useState(false);
  const [publishCompletedModalOpen, setPublishCompletedModalOpen] = useState(false);
  const [lastPublished, setLastPublished] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (published) {
      setLastPublished(published);
    }
  }, [published]);

  const form = useForm({
    initialValues: {
      title: '',
      favicon: '',
      description: '',
      urlSlug: '',
      googleTagManagerId: '',
    },
    validateInputOnChange: true,
    validate: {
      title: validateTitle,
      urlSlug: validateSlug,
      googleTagManagerId: (value) => {
        if (!value) return null;

        return validator.matches(value, /^GTM-[A-Z0-9]{1,8}$/i)
          ? null
          : 'Invalid Google Tag Manager ID format, must start with "GTM-"';
      },
    },
  });

  const formDomain = useForm({
    initialValues: {
      domain: '',
      cname: '',
      error: false,
    },
    validateInputOnChange: true,
    validate: {
      domain: (value) => (value.length > 0 && !validator.isFQDN(value) ? 'Invalid domain' : null),
    },
  });

  const formForNotContent = useForm<{
    nanoId: string;
    workspaceId: string;
    lastPublishedDate?: number;
  }>({
    initialValues: {
      nanoId: '',
      workspaceId: '',
      lastPublishedDate: undefined,
    },
  });

  useEffect(() => {
    if (opened) {
      msg({ type: 'editor-sync' });
      msg({ type: 'trigger-broadcast-page-data' });
    } else {
      form.reset();
      formForNotContent.reset();
      formDomain.reset();
    }
  }, [opened]);

  useEffect(() => {
    if (selectedVariant) {
      setIsCurrentVariantEdited(false);
      const formObj = {
        ...form.values,
        urlSlug: selectedVariant.definition ?? '',
        title: selectedVariant.title ?? '',
        description: selectedVariant.description ?? '',
      };
      form.setValues(formObj);
      form.resetDirty(formObj);
      const formForNotContentOjb = {
        nanoId: selectedVariant.nanoId ?? '',
        workspaceId: selectedVariant.workspaceId ?? '',
        lastPublishedDate: selectedVariant.lastPublishedDate,
      };
      formForNotContent.setValues(formForNotContentOjb);
      formForNotContent.resetDirty(formForNotContentOjb);
    }
  }, [selectedVariant]);

  useViewerMessage(
    async ({ data }) => {
      if (data.type === 'broadcast-page-data' && opened) {
        const parsedPageData = JSON.parse(data.pageData || '{}');
        const { favicon = '', title = '', head = [], googleTagManagerId = '' } = parsedPageData;
        let { description = '' } = parsedPageData;

        const {
          nanoId,
          definition: urlSlug,
          workspaceId = '',
          lastPublishedDate,
        } = data.pageDetails;

        let obj;
        let objForNotContent;

        // We don't refresh the studio page after updating the page variant
        // so we don't have the new updated data in the selectedVariant
        if (isCurrentVariantEdited) {
          obj = { ...form.values };
          objForNotContent = {
            ...formForNotContent.values,
          };
          // If the page is a variant, we just get the favicon and googleTagManagerId
          // that are saved at the page level
        } else if (selectedVariant) {
          obj = {
            ...form.values,
            favicon,
            googleTagManagerId,
          };
          objForNotContent = {
            ...formForNotContent.values,
          };
        } else {
          if (description === '') {
            description =
              head.find((tag: PageJsonSnippetObj) => tag['@name'] === 'description')?.[
                '@content'
              ] || '';
          }

          obj = {
            title,
            favicon,
            description,
            googleTagManagerId,
            urlSlug,
          };

          objForNotContent = {
            nanoId,
            workspaceId,
            lastPublishedDate,
          };
        }

        form.setValues(obj);
        form.resetDirty(obj);
        formForNotContent.setValues(objForNotContent);
        formForNotContent.resetDirty(objForNotContent);

        setLoadDomain(true);
        try {
          const getDomainResult = await getDomain({
            workspaceId: selectedVariant?.workspaceId ?? workspaceId,
          });
          if (getDomainResult.domain) {
            formDomain.setValues(getDomainResult);
            formDomain.resetDirty({
              domain: getDomainResult.domain,
              cname: getDomainResult.cname,
              error: false,
            });
            setLoadDomain(false);
          }
        } catch {
          setLoadDomain(false);
        }
      }

      if (data.type === 'show-modal' && data.modal === 'publish-completed') {
        const panelName = 'publish-confirmation';
        setOpenedSub(openedSub === panelName ? '' : panelName || '');
        notifications.show({
          color: 'blue',
          message: selectedVariant
            ? 'Variants published successfully!'
            : 'Page published successfully!',
          autoClose: 3000,
        });
        setPublishCompletedModalOpen(true);
      }
    },
    [
      opened,
      form,
      formForNotContent,
      formDomain,
      selectedVariant,
      isCurrentVariantEdited,
      openedSub,
    ],
  );

  useEffect(() => {
    setOpenedSub(makeOpenSubPanel?.type ?? '');
  }, [makeOpenSubPanel]);

  const handleClickToToggleSubPanel = (panelName: SubPanelTypes | PublishSubPanelTypes) => {
    setOpenedSub(openedSub === panelName ? '' : panelName || '');
  };

  return (
    <Collapse in={opened} transitionDuration={100} transitionTimingFunction="linear">
      <SubMenuContainer style={{ borderRadius: '0px 4px 4px 4px' }} data-editor-sync-when-mousedown>
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Settings"
          label="Publish Settings"
          panel="publish-settings"
          icon={<IconBrowserCheck size={16} />}
        />
        <IconChevronRight size={14} />
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Go Live"
          label="Go Live"
          panel="publish-go-live"
          icon={<IconArrowBarUp size={16} />}
        />
      </SubMenuContainer>

      {Object.entries(publishSubPanelCollection).map(([key, obj]) => {
        const Panel = obj.component;
        return (
          <Panel
            key={key}
            opened={openedSub === key}
            onClickToClose={() => setOpenedSub('')}
            form={form}
            formForNotContent={formForNotContent}
            formDomain={formDomain}
            setIsCurrentVariantEdited={setIsCurrentVariantEdited}
            loadDomain={loadDomain}
          />
        );
      })}

      <PublishingVariantLightbox />

      <Modal
        opened={publishCompletedModalOpen}
        onClose={() => setPublishCompletedModalOpen(false)}
        title={
          <b style={{ fontSize: '1.5rem' }}>
            <IconConfetti color="var(--mantine-color-upflowy-purple-filled)" /> Publishing complete
          </b>
        }
        size="42%"
        zIndex={99999}
        centered
      >
        <PublishCompleted
          onClose={() => setPublishCompletedModalOpen(false)}
          published={lastPublished}
        />
      </Modal>
    </Collapse>
  );
};

export default PublishSettingsMenu;
