export const validateCodeEmbed = (value: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'application/xml');
  const errorNode = doc.querySelector('parsererror');
  if (errorNode) {
    return 'The embed code you entered is not valid. Please check the format and try again.';
  }

  return null;
};
