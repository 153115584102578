import { PageJsonSnippetObj } from '~/global.types';

const removeIsEditedProperty = (obj: PageJsonSnippetObj) => {
  const updatedObject = JSON.parse(JSON.stringify(obj));

  for (const key in updatedObject) {
    if (typeof updatedObject[key] === 'object') {
      if (updatedObject[key]['@data-edited']) {
        delete updatedObject[key]['@data-edited'];
      }
      updatedObject[key] = removeIsEditedProperty(updatedObject[key]);
    }
  }

  return updatedObject;
};

export default removeIsEditedProperty;
