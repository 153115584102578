import { Group, Loader, Modal, Stack, Text } from '@mantine/core';

import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

const PublishingVariantLightbox = () => {
  const { published, toBePublishedCount } = usePageSet();

  const totalToBePublishedCount = published ? Object.values(published) : [];
  const hasPublished = totalToBePublishedCount.filter((val) => val === true).length;

  return (
    <Modal
      onClose={() => null}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      opened={!!published || false}
      size={355}
      zIndex={99999}
      centered
      radius={8}
      padding={40}
    >
      <Stack align="center" gap={0}>
        <img src="/assets/generating-variants.svg" />

        <Text mt={24} mb={8} fz={12} fw={600}>
          Publishing your page variants
        </Text>

        <Group mb={24} gap={8}>
          <Loader size={14} />
          <Text fz={12} fw={400}>
            {`Publishing ${hasPublished} of ${toBePublishedCount}`}
          </Text>
        </Group>
      </Stack>
    </Modal>
  );
};

export default PublishingVariantLightbox;
