import { ActionIcon, Flex, Paper } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconMicrophone, IconSparkles } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import ShortUniqueId from 'short-unique-id';

import { IconSend, ScrollMessage, StyledTextInput } from './BriefPanel.styles';
import Message, { ChatMessage } from './Message';
import { callChatBot } from './callChatBot';

import BasePanel from '../BasePanel/BasePanel';

import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

interface BriefPanelProps {
  opened: boolean;
  onClickToClose?: () => void;
  isClosable?: boolean;
}

type ChatState = {
  chatHistory: ChatMessage[];
  chatUID: string;
  conversationFinished: boolean;
};

const chatHistoryWelcome: ChatMessage[] = [
  {
    message: [
      "👋 Hi, I'm Flo, your marketing copilot. ",
      'We already understand your brand from the provided URL. ',
      "I'll now ask 11 briefing questions for your new landing page. ",
      "Don't overthink it, I'm able to fill in the gaps.",
    ].join(''),
    role: 'AI',
  },
];
const { randomUUID } = new ShortUniqueId();

const BriefPanel = ({ opened, onClickToClose, isClosable = false }: BriefPanelProps) => {
  const [nanoId, setNanoId] = useState<string>();
  const [newMessage, setNewMessage] = useState('');
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (opened) {
      msg({ type: 'trigger-broadcast-page-data' });
    }
  }, [opened]);

  useViewerMessage(
    async ({ data }) => {
      if (data.type === 'broadcast-page-data' && opened) {
        setNanoId(data.pageDetails.nanoId);
      }
    },
    [opened],
  );

  const [chatState, setChatState] = useLocalStorage<ChatState>({
    key: nanoId ? `chatState_${nanoId}` : 'default',
    defaultValue: {
      chatHistory: chatHistoryWelcome,
      chatUID: randomUUID(),
      conversationFinished: false,
    },
    getInitialValueInEffect: nanoId === undefined,
  });

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [chatState]);

  const handleSendMessage = async () => {
    if (!nanoId) {
      return;
    }
    if (newMessage.trim() !== '') {
      const updatedChatHistory = [
        ...chatState.chatHistory,
        { message: newMessage, role: 'Human' as const },
      ];
      setChatState((prevState) => ({
        ...prevState,
        chatHistory: updatedChatHistory,
      }));
      setNewMessage('');
      try {
        // Call the chatbot with the last message and the chat history excluding the last message
        const response = await callChatBot(
          chatState.chatUID,
          newMessage,
          updatedChatHistory.slice(0, -1),
          nanoId,
        );

        if (response) {
          if (typeof response != 'string') {
            setChatState((prevState) => ({
              ...prevState,
              chatHistory: [
                ...updatedChatHistory,
                { message: "Sorry, I'm not sure how to respond to that.", role: 'AI' },
              ],
            }));
          } else if (response === 'Thanks for your responses! Building new landing page...') {
            setChatState((prevState) => ({
              chatHistory: [...updatedChatHistory, { message: response, role: 'AI' }],
              chatUID: prevState.chatUID,
              conversationFinished: true,
            }));
            //Intentionally breaking the chatbot allows restarting the conversation elsewhere
            callChatBot(chatState.chatUID, 'restart', updatedChatHistory.slice(0, -1), nanoId);
            msg({ type: 'complete-initial-brief' });
          } else
            setChatState((prevState) => ({
              ...prevState,
              chatHistory: [...updatedChatHistory, { message: response, role: 'AI' }],
            }));
        } else {
          setChatState((prevState) => ({
            ...prevState,
            chatHistory: [
              ...updatedChatHistory,
              { message: "Sorry, I'm not sure how to respond to that.", role: 'AI' },
            ],
          }));
        }
      } catch (error) {
        setChatState((prevState) => ({
          ...prevState,
          chatHistory: [
            ...updatedChatHistory,
            { message: 'Sorry, an error occurred while contacting the chatBot.', role: 'AI' },
          ],
        }));
      }
    }
  };

  useEffect(() => {
    async function callFirstMessage(pageId: string) {
      const chatUID = randomUUID();
      const firstMessage = 'I want to create a new landing page';
      const response = await callChatBot(
        chatUID,
        firstMessage,
        [chatHistoryWelcome[0], { message: firstMessage, role: 'Human' as const }].slice(0, -1),
        pageId,
      );

      if (response) {
        setChatState({
          chatHistory: [...chatHistoryWelcome, { message: response, role: 'AI' }],
          chatUID,
          conversationFinished: false,
        });
      } else {
        setChatState({
          chatHistory: [...chatHistoryWelcome, { message: 'Sorry an error happened', role: 'AI' }],
          chatUID,
          conversationFinished: false,
        });
      }
      setLoading(false);
    }
    if (nanoId) {
      const storedState = localStorage.getItem(`chatState_${nanoId}`);
      if (storedState) {
        const parsedState = JSON.parse(storedState);
        setChatState(parsedState);
        if (parsedState.conversationFinished) {
          msg({ type: 'complete-initial-brief' });
        }
        setLoading(false);
      } else {
        callFirstMessage(nanoId);
      }
    }
  }, [nanoId, setChatState]);

  const handleRemoveChatToForceRestart = () => {
    setChatState({ ...chatState, conversationFinished: true });
    localStorage.removeItem(`chatState_${nanoId}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  if (!nanoId) {
    return null;
  }

  return (
    <BasePanel
      opened={opened}
      onClickToClose={onClickToClose}
      label="ChatBot"
      title="Brief"
      isClosable={isClosable}
      icon={<IconMicrophone />}
    >
      <Paper
        shadow="sm"
        radius="md"
        p={16}
        pr={16}
        bg="#F1F3F5"
        h="calc(100vh - 153px)"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <ScrollMessage ref={scrollAreaRef}>
          {!loading &&
            chatState.chatHistory
              .slice()
              .reverse()
              .map((msgItem: ChatMessage, index: number) => (
                <Message key={index} {...msgItem} onRemoveChat={handleRemoveChatToForceRestart} />
              ))}
        </ScrollMessage>
        <Flex mt="md" align="center" pt={10} gap="4px">
          <StyledTextInput
            value={newMessage}
            onKeyDown={handleKeyDown}
            onChange={(e) => setNewMessage(e.currentTarget.value)}
            placeholder={
              !chatState.conversationFinished ? '' : 'Please wait for the page to be built.'
            }
            style={{ flex: 1 }}
            leftSection={<IconSparkles size={16} />}
            aria-label="chat-box"
            disabled={chatState.conversationFinished}
          />
          <ActionIcon
            onClick={handleSendMessage}
            size={36}
            style={{ border: 0 }}
            bg={
              !chatState.conversationFinished
                ? 'linear-gradient(180deg, #D027D3 0%, #7C1ACB 31.5%, #5E27D3 66.5%, #27AAD3 100%)'
                : '#868e96'
            }
            disabled={chatState.conversationFinished}
            aria-label="send-message-button"
          >
            <IconSend />
          </ActionIcon>
        </Flex>
      </Paper>
    </BasePanel>
  );
};

export default BriefPanel;
