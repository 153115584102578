import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { FeatureFlags, UserAndWorkspaceInfo } from '~/global.types';
import { fetchFeatureFlags } from '~/services/FeatureFlagServices';
import { getUser, hasActiveSubscription, isUserAuthenticated } from '~/services/UserServices';
import { getWorkspaces } from '~/services/WorkspaceServices';

interface FeatureFlagContextType {
  isLoadingSubscription: boolean;
  isLoadingWorkspace: boolean;
  featureFlags: FeatureFlags;
  userAndWorkspaceInfo: UserAndWorkspaceInfo;
  updateUserAndWorkspace: (userAndWorkspaceInfo: Partial<UserAndWorkspaceInfo>) => void;
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(undefined);

// eslint-disable-next-line react-refresh/only-export-components
export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }
  return context;
};

interface FeatureFlagProviderProps {
  children: ReactNode;
}

const defaultFeatureFlags = {
  hasV2Access: {
    enabled: false,
  },
  allowDevMode: {
    enabled: false,
  },
  pageThumbnailService: {
    enabled: false,
  },
  manageWorkspace: {
    enabled: false,
  },
  allowPageSets: {
    enabled: false,
  },
  allowNewPublishUI: {
    enabled: false,
  },
  codeEmbedAccess: {
    enabled: false,
  },
};

const defaultUserAndWorkspaceInfo = {
  hasActiveIntentSubscription: false,
  hasActiveV2Subscription: false,
  hasIntentWorkspace: false,
  hasV2Workspace: false,
  workspaces: [],
  intentActiveWorkspace: undefined,
  v2ActiveWorkspace: undefined,
  intentProperties: [],
  intentWorkspaces: [],
  v2Workspaces: [],
  user: undefined,
};

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({ children }) => {
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [isLoadingWorkspace, setIsLoadingWorkspace] = useState(true);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  const [userAndWorkspaceInfo, setUserAndWorkspaceInfo] = useState<UserAndWorkspaceInfo>(
    defaultUserAndWorkspaceInfo,
  );

  const updateUserAndWorkspace = (flagUserAndWorkspaceInfo: Partial<UserAndWorkspaceInfo>) => {
    setUserAndWorkspaceInfo((userWorkspaceInfo) => ({
      ...userWorkspaceInfo,
      ...flagUserAndWorkspaceInfo,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isAuthenticated = await isUserAuthenticated();
        if (!isAuthenticated) {
          const flags = await fetchFeatureFlags();
          setFeatureFlags(flags);
          return;
        }
        const [userData, { workspaces }] = await Promise.all([getUser(), getWorkspaces()]);
        const flags = await fetchFeatureFlags(userData.email);
        setFeatureFlags(flags);

        const intentWorkspaces = workspaces.filter((workspace) => workspace.type === 'INTENT');
        const v2Workspaces = workspaces.filter((workspace) => workspace.type === 'V2');
        const hasIntentWorkspace = intentWorkspaces.length > 0;
        const hasV2Workspace = v2Workspaces.length > 0;

        const intentActiveWorkspace = hasIntentWorkspace ? intentWorkspaces[0] : undefined;
        const intentProperties = Object.values(intentActiveWorkspace?.properties || []);
        const hasLocalWorkspaceId = localStorage.getItem('activeWorkspace');

        if (!hasLocalWorkspaceId && hasV2Workspace) {
          localStorage.setItem('activeWorkspace', v2Workspaces[0].workspaceId);
        }

        const v2workspace =
          v2Workspaces.find((workspace) => workspace.workspaceId === hasLocalWorkspaceId) ||
          v2Workspaces[0];

        const v2ActiveWorkspace = hasV2Workspace ? v2workspace : undefined;

        updateUserAndWorkspace({
          hasIntentWorkspace,
          hasV2Workspace,
          workspaces,
          intentActiveWorkspace,
          intentWorkspaces,
          v2Workspaces,
          v2ActiveWorkspace,
          intentProperties,
        });

        const [hasActiveIntentSubscription, hasActiveV2Subscription] = await Promise.all([
          hasActiveSubscription(userData, 'INTENT'),
          hasActiveSubscription(userData, 'V2'),
        ]);

        updateUserAndWorkspace({
          user: userData,
          hasActiveIntentSubscription,
          hasActiveV2Subscription,
        });
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoadingSubscription(false);
        setIsLoadingWorkspace(false);
      }
    };

    fetchData();
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={{
        isLoadingSubscription,
        isLoadingWorkspace,
        featureFlags,
        userAndWorkspaceInfo,
        updateUserAndWorkspace,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};
