import {
  ActionIcon,
  Box,
  Button,
  FileButton,
  Flex,
  Group,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBrowserCheck, IconInfoCircle, IconTrash } from '@tabler/icons-react';
import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';

import BasePanel from '../BasePanel/BasePanel';
import { FormForNotContentType, FormType } from '../PublishSettings/PublishSettingsMenu';

import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';
import { uploadFile } from '~/services/AssetServices';
import { updatePageVariant } from '~/services/PageSetServices/PageSetServices';

export interface PublishSettingsPanelProps {
  opened: boolean;
  onClickToClose: () => void;
  setIsCurrentVariantEdited: Dispatch<SetStateAction<boolean>>;
  form: FormType;
  formForNotContent: FormForNotContentType;
}

const {
  VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME = '*.testing.upflowyexperience.com',
  VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME,
} = import.meta.env || {};

const PublishSettingsPanel = ({
  opened,
  onClickToClose,
  setIsCurrentVariantEdited,
  form,
  formForNotContent,
}: PublishSettingsPanelProps) => {
  const { selectedVariant } = usePageSet();
  const [faviconFile, setFaviconFile] = useState<File | null>(null);
  const [savingSettings, setSavingSettings] = useState(false);
  const resetFileButtonRef = useRef<() => void>(null);

  const getUrlDescription = () => {
    if (VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME) {
      return 'URL path not shown in dev environment';
    }
    return `https://${VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME.replace(
      '*',
      formForNotContent.values.workspaceId,
    )}/${form.values.urlSlug}`;
  };

  const urlDescription = useMemo(
    () => getUrlDescription(),
    [formForNotContent.values.workspaceId, form.values.urlSlug],
  );

  const handleDeleteVariant = () => {
    msg({ type: 'show-modal', modal: 'delete-variant' });
  };

  const onClickToOpenGoLivePanel = () => {
    msg({
      type: 'open-toolbar-panel',
      openToolbarPanel: 'publish-settings',
      openToolbarSubPanel: 'publish-go-live',
    });
  };

  const clearFile = () => {
    setFaviconFile(null);
    resetFileButtonRef.current?.();
  };

  useViewerMessage(
    async ({ data }) => {
      if (
        ['update-page-content-success', 'editing-publish-settings-success'].includes(data.type) &&
        opened &&
        savingSettings
      ) {
        if (selectedVariant) {
          try {
            await updatePageVariant({
              nanoId: formForNotContent.values.nanoId,
              definition: form.values.urlSlug,
              title: form.values.title,
              description: form.values.description.length > 0 ? form.values.description : undefined,
            });
            setIsCurrentVariantEdited(true);
          } catch (error) {
            notifications.show({
              color: 'red',
              message:
                error instanceof Error
                  ? JSON.parse(error.message).message
                  : 'Failed to update page variant settings',
              autoClose: 3000,
            });
            setSavingSettings(false);
            return;
          }
        }
        form.resetDirty(form.values);
        notifications.show({
          color: 'blue',
          message: selectedVariant
            ? 'Page variant settings updated successfully!'
            : 'Page settings updated successfully!',
          autoClose: 3000,
        });
        setSavingSettings(false);
      }

      if (data.type === 'update-page-content-failed' && opened && savingSettings) {
        let message = 'Failed to update page settings!';
        try {
          message = JSON.parse(data.outputData.errorMessage).message;
        } catch (error) {
          //empty error to ensure json parse error is caught
        }
        notifications.show({
          color: 'red',
          message,
          autoClose: 3000,
        });
        setSavingSettings(false);
      }
    },
    [opened, form, formForNotContent, savingSettings, selectedVariant],
  );

  const handleSave = async () => {
    setSavingSettings(true);
    const { workspaceId } = formForNotContent.values;

    if (opened && faviconFile) {
      let uploadResponse;
      try {
        msg({ type: 'upload-to-collection', file: faviconFile });
        // The below uploads to assets folder via Asset Handling Service
        uploadResponse = await uploadFile({ workspaceId, file: [faviconFile] });
      } catch {
        notifications.show({
          color: 'red',
          message: 'Something wrong during new favicon uploads. Halting the publish process.',
          autoClose: 3000,
        });
        setSavingSettings(false);
        return;
      }

      const favicon = `${uploadResponse.filePaths[0]}`;

      notifications.show({
        color: 'blue',
        message: 'New favicon uploads successfully!',
        autoClose: 3000,
      });

      msg({
        type: selectedVariant ? 'editing-publish-settings-variant' : 'editing-publish-settings',
        outputData: { ...form.values, favicon },
      });

      form.setFieldValue('favicon', favicon);
      setFaviconFile(null);
    } else if (opened && form.isDirty()) {
      // If there is no new favicon, but other form is edited
      msg({
        type: selectedVariant ? 'editing-publish-settings-variant' : 'editing-publish-settings',
        outputData: { ...form.values },
      });
    }
  };

  return (
    <>
      <BasePanel
        opened={opened}
        onClickToClose={onClickToClose}
        label="Publish"
        title={'Settings' + (selectedVariant?.name ? `: Variant ${selectedVariant?.name}` : '')}
        icon={<IconBrowserCheck />}
        publishButton={
          <Box p={16} style={{ boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)' }}>
            {form.isDirty() || faviconFile ? (
              <Flex direction="row" gap={15}>
                <Button
                  variant="outline"
                  size="md"
                  color="var(--mantine-color-gray-4)"
                  c="black"
                  w="100%"
                  fw={400}
                  onClick={() => {
                    form.reset();
                    clearFile();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  w="100%"
                  size="md"
                  fw={400}
                  disabled={!form.isValid()}
                  onClick={handleSave}
                  loading={savingSettings}
                  loaderProps={{ type: 'dots' }}
                >
                  Save
                </Button>
              </Flex>
            ) : (
              <Button w="100%" size="md" onClick={onClickToOpenGoLivePanel} fw={400}>
                Next
              </Button>
            )}
          </Box>
        }
      >
        <Flex direction="column" gap={32} w="100%">
          <TextInput
            leftSection={<span>/</span>}
            label={
              <Group gap={4}>
                <div>URL Slug</div>
                <Tooltip
                  fz={12}
                  position="top-start"
                  label={
                    <>
                      Customize the URL for this variant page.
                      <br />
                      Use hyphens to separate words and
                      <br />
                      avoid spaces or special characters
                    </>
                  }
                  withinPortal={false}
                  withArrow
                  color="var(--mantine-color-gray-7)"
                >
                  <IconInfoCircle size={16} />
                </Tooltip>
              </Group>
            }
            size="sm"
            mt="md"
            description={urlDescription}
            disabled={savingSettings}
            {...form.getInputProps('urlSlug')}
            onChange={(event) => {
              const value = event.currentTarget.value.toLowerCase();
              const formattedSlug = value.replace(/\s+/g, '-');
              form.setFieldValue('urlSlug', formattedSlug);
            }}
          />

          <Flex direction="column">
            <Title order={6} fw={500}>
              SEO Page title and favicon
            </Title>
            <Flex align="center" gap={8} mt={4} w="100%">
              <FileButton
                onChange={setFaviconFile}
                accept="image/png,image/svg+xml"
                inputProps={{ name: 'favicon' }}
                disabled={savingSettings}
                resetRef={resetFileButtonRef}
              >
                {(props) => {
                  let src = form.values.favicon || '/logolight-upflowyicon1.svg';
                  const { workspaceId } = formForNotContent.values;

                  if (form.values.favicon.length > 0) {
                    if (/^(https?:\/\/|\/)/.test(form.values.favicon)) {
                      src = form.values.favicon;
                    } else if (VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME) {
                      src = `https://${VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME}/${workspaceId}/${form.values.favicon}`;
                    } else {
                      src = `https://${VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME.replace(
                        '*',
                        workspaceId,
                      )}/${workspaceId}/${form.values.favicon}`;
                    }
                  }

                  if (faviconFile) {
                    src = URL.createObjectURL(faviconFile);
                  }

                  return (
                    <ActionIcon
                      {...props}
                      variant="default"
                      aria-label="Upload favicon"
                      size={36}
                      disabled={savingSettings}
                    >
                      <Box p={4}>
                        <img
                          src={src}
                          width="100%"
                          height="100%"
                          alt="favicon"
                          onError={(e) => {
                            (e.target as HTMLImageElement).src = '/logolight-upflowyicon1.svg';
                          }}
                        />
                      </Box>
                    </ActionIcon>
                  );
                }}
              </FileButton>
              <TextInput
                width="100%"
                aria-label="Set page title"
                placeholder="Title displays on the browser tab"
                size="sm"
                style={{ flex: '1 1 100%' }}
                disabled={savingSettings}
                {...form.getInputProps('title')}
                error={!!form.errors.title}
              />
            </Flex>
            {form.errors.title && (
              <Text c="var(--mantine-color-error)" fz={12} mt={5}>
                {form.errors.title}
              </Text>
            )}
          </Flex>

          <Textarea
            placeholder="Description meta tag"
            label="SEO Page description"
            size="sm"
            minRows={4}
            disabled={savingSettings}
            {...form.getInputProps('description')}
          />

          {selectedVariant?.name && (
            <Button
              variant="outline"
              color="var(--mantine-color-red-8)"
              size="sm"
              h={32}
              leftSection={<IconTrash size={16} />}
              fw={400}
              onClick={handleDeleteVariant}
            >
              Delete variant
            </Button>
          )}
        </Flex>
      </BasePanel>
    </>
  );
};

export default PublishSettingsPanel;
