import { notifications } from '@mantine/notifications';
import Userfront from '@userfront/toolkit';
import {
  Product,
  ProfilePayload,
  Role,
  Subscription,
  User,
  UsersWithWorkspaceApiResult,
} from 'global.types';

import ApiClient from '~/helpers/ApiClient';
import { FilterParams } from '~/screens/AdminDashboard/types';

export const awsAPIClient = new ApiClient(import.meta.env?.VITE_AWS_API_BASE_URL);

export const refreshUserToken = async () => {
  await Userfront.tokens.refresh();
};

export const getUser = async (): Promise<User> => {
  const user = await awsAPIClient.get<User>('/get-user');
  return user;
};

export const getAllUsersWithWorkspace = async (
  filterParams: FilterParams,
): Promise<UsersWithWorkspaceApiResult> => {
  const url = `/get-users-with-workspace`;
  const params: string[] = [];
  Object.entries(filterParams).forEach(([key, value]) => {
    if (value) params.push(`${key}=${value}`);
  });
  return await awsAPIClient.get<UsersWithWorkspaceApiResult>(`${url}?${params.join('&')}`);
};

export const isUserAuthenticated = async () => {
  const token = Userfront.tokens.accessToken;
  if (!token) await refreshUserToken();
  return !!Userfront.tokens.accessToken;
};

export const getAuthedUser = async () => {
  const authSuccess = await isUserAuthenticated();

  if (!authSuccess) {
    notifications.show({
      color: 'red',
      title: 'User not authenticated',
      message: '',
      autoClose: 3000,
    });
  }

  return authSuccess ? Userfront.user : null;
};

export const hasActiveSubscription = (user: User, product: Product) => {
  const { data } = user;
  if (!data?.subscriptions) return false;

  return (
    (Object.values(data?.subscriptions ?? {}) as Subscription[]).filter(
      (sub) => product === sub.product && sub.status === 'active',
    ).length > 0
  );
};

export const updateProfile = async (payload: Partial<ProfilePayload>, skipNotification = false) => {
  const user = await awsAPIClient.put<User>('/update-user-profile', payload);
  if (!skipNotification) {
    const message = payload.enableEmailNotification
      ? 'Email notification on'
      : 'Email notification off';

    notifications.show({
      color: 'blue',
      title: payload.name ? 'Profile updated successfully!' : message,
      message: '',
      autoClose: 3000,
    });
  }
  return user;
};

export const isSuperAdmin = () => Userfront.user.hasRole && Userfront.user.hasRole('superadmin');

type InviteUserPayload = {
  email: string;
  workspaceId: string;
  role: Role;
  sendEmailInvitation: boolean;
};

export const inviteUserToWorkspace = async (inviteUserPayload: InviteUserPayload) => {
  return await awsAPIClient.post<{ message: string }>(
    '/invite-user-to-workspace',
    inviteUserPayload,
  );
};

export type Message = {
  eventType: string;
  statusCode: string;
  messageId: string;
  objectId: string;
  messageDatetime: number;
  userId: string;
};

export type GetSubscription = {
  subscriptionId: string;
};

export const getMessages = async ({
  eventType,
  objectId,
}: {
  eventType?: string;
  objectId?: string;
}) => {
  return await awsAPIClient.post<{ data: Message[] }>('/get-notifications', {
    ...(eventType && { eventType }),
    ...(objectId && { objectId }),
  });
};

export const getSubscriptions = async () => {
  return await awsAPIClient.post<{ data: GetSubscription[] }>('/get-subscriptions', {});
};
