import {
  VariantAction,
  VariantActionState,
  VariantGenerationState,
  VariantPublishState,
} from '../types';

export const variantWebSocketReducer = <T extends VariantPublishState | VariantGenerationState>(
  state: VariantActionState<T>,
  action: VariantAction,
): VariantActionState<T> => {
  switch (action.type) {
    case 'prepare-to-process':
      return {
        ...state,
        isProcessing: true,
        processed: {},
      };
    case 'init-process':
      return {
        ...state,
        isProcessing: true,
        processed:
          action.payload.processedVariants ||
          Object.fromEntries(action.payload.pageVariantList.map((id) => [id, false])),
        toBeProcessedCount: action.payload.pageVariantList.length,
        lastActionResult: action.payload.lastActionResult as Record<string, T>,
      };
    case 'mark-processed':
      if (!state.processed) return state;
      return {
        ...state,
        processed: {
          ...state.processed,
          [action.objectId]: true,
        },
        lastActionResult: {
          ...state.lastActionResult,
          [action.objectId]: action.statusCode as T,
        },
      };
    case 'end-process':
      return {
        ...state,
        isProcessing: false,
        processed: null,
        lastActionCompleteTimestamp: new Date().toISOString(),
      };
    case 'reset-state':
      return {
        isProcessing: false,
        processed: null,
        toBeProcessedCount: 0,
      };
    default:
      return state;
  }
};
