import { PageSetVariantStateObj } from '../types';

import { updatePageSet } from '~/services/PageSetServices/PageSetServices';

type VariantAction =
  | { type: 'set-selected-variant-is-edited'; pageSetId: string; selectedVariantNanoId?: string }
  | { type: 'reset-variant-state' }
  | { type: 'override-variant-state'; payload: PageSetVariantStateObj };

// This is only used by notification service to store publish and generation state
const variantStateReducer = (
  state: PageSetVariantStateObj,
  action: VariantAction,
): PageSetVariantStateObj => {
  switch (action.type) {
    case 'set-selected-variant-is-edited': {
      const updatedState = {
        ...state,
        editedVariant: action.selectedVariantNanoId,
      };

      updatePageSet({
        nanoId: action.pageSetId,
        state: updatedState,
      });

      return updatedState;
    }

    case 'reset-variant-state':
      return {};

    case 'override-variant-state':
      return action.payload;

    default:
      return state;
  }
};

export default variantStateReducer;
