import { Box } from '@mantine/core';
import { IconGripVertical, IconTypography } from '@tabler/icons-react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';

import BasePanel from '../BasePanel/BasePanel';

import { GENERIC_BODY_TEXT_UNIT_STYLE, GENERIC_HEADING_TEXT_UNIT_STYLE } from '~/constants';
import { ToolbarPanelProps } from '~/global.types';
import {
  addListenerForViewerDropzones,
  removeListenerForViewerDropzones,
} from '~/helpers/viewerInteractions/dropzoneEventHandling';

interface AddTextProps {
  darkVariant?: boolean;
  dataItem?: {
    tagName?: string;
    textValue?: string;
    style?: Record<string, string>;
    type?: 'text';
  };
  label?: string;
}

const DraggableElement = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 8px;
  background: #f1f3f5;
  border-radius: 4px;
  box-shadow:
    0px 1px 3px 0px #0000000d,
    0px 1px 2px 0px #0000001a;

  & > * {
    margin: 0;
    pointer-events: none;
  }

  &:hover {
    box-shadow:
      0px 1px 3px 0px #0000000d,
      0px 4px 3.799999952316284px 0px #0000001a;
  }

  svg {
    color: transparent;
  }

  &:hover svg {
    color: #868e96;
  }
`;

interface TagProps {
  darkVariant?: boolean;
}

const Tag = styled.div<TagProps>`
  flex: inherit;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  color: ${({ darkVariant }) =>
    darkVariant ? 'var(--color-text-darkbg)' : 'var(--color-text-lightbg)'};
  font-family: ${({ as }) =>
    as?.toString().startsWith('h')
      ? 'var(--font-family-headings)'
      : 'var(--font-family-paragraphs)'};
`;

const AddText = ({ darkVariant, dataItem = {}, label }: AddTextProps) => {
  const [{ opacity, cursor }, dragRef, dragPreview] = useDrag(
    () => ({
      type: 'add-page-element',
      item: dataItem,
      collect: (monitor) => {
        if (monitor.isDragging()) addListenerForViewerDropzones();
        return {
          opacity: monitor.isDragging() ? 0.5 : 1,
          cursor: monitor.isDragging() ? 'grabbing' : 'grab',
        };
      },
      end: removeListenerForViewerDropzones,
    }),
    [],
  );

  return (
    <>
      <DraggableElement
        ref={dragRef}
        style={{
          position: 'relative',
          opacity,
          cursor,
          ...(darkVariant && { background: '#444' }),
        }}
      >
        <Tag as={dataItem.tagName} darkVariant={darkVariant}>
          {label || dataItem.tagName?.toUpperCase()}
        </Tag>
        <IconGripVertical size={12} />

        <DraggableElement
          ref={dragPreview}
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            zIndex: -99,
            fontSize: '50%',
          }}
        >
          <Tag as={dataItem.tagName} darkVariant={darkVariant}>
            {label || dataItem.tagName?.toUpperCase()}
          </Tag>
        </DraggableElement>
      </DraggableElement>
    </>
  );
};

const style = GENERIC_HEADING_TEXT_UNIT_STYLE;

const AddTextPanel = ({ opened, handleClickToToggleSubPanel }: ToolbarPanelProps) => {
  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('add-text')}
      label="Add text options"
      title="Text"
      icon={<IconTypography />}
    >
      <Box>
        <AddText
          label="Heading 1"
          dataItem={{ tagName: 'h1', textValue: '', style, type: 'text' }}
        />
        <AddText
          label="Heading 2"
          dataItem={{ tagName: 'h2', textValue: '', style, type: 'text' }}
        />
        <AddText
          label="Heading 3"
          dataItem={{ tagName: 'h3', textValue: '', style, type: 'text' }}
        />
        <AddText
          label="Heading 4"
          dataItem={{ tagName: 'h4', textValue: '', style, type: 'text' }}
        />
        <AddText
          label="Heading 5"
          dataItem={{ tagName: 'h5', textValue: '', style, type: 'text' }}
        />
        <AddText
          label="Heading 6"
          dataItem={{ tagName: 'h6', textValue: '', style, type: 'text' }}
        />
        <AddText
          label="Body"
          dataItem={{
            tagName: 'div',
            style: GENERIC_BODY_TEXT_UNIT_STYLE,
            textValue: '',
            type: 'text',
          }}
        />
      </Box>
    </BasePanel>
  );
};

export default AddTextPanel;
