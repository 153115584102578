import { Text } from '@mantine/core';
import React from 'react';

import { ActiveBar, ButtonContentSubMenu, StyledButtonSubMenu } from '../Toolbar.styles';

import { PublishSubPanelTypes, SubPanelTypes } from '~/global.types';

interface BaseSubMenuButtonProps {
  openedSub: string;
  label?: string;
  displayText: string;
  panel?: SubPanelTypes | PublishSubPanelTypes;
  icon: React.ReactNode;
  className?: string;
  handleClickToToggleSubPanel: (panelName: SubPanelTypes | PublishSubPanelTypes) => void;
}

const BaseSubMenuButton = ({
  openedSub,
  label,
  displayText,
  panel,
  icon,
  className,
  handleClickToToggleSubPanel,
}: BaseSubMenuButtonProps) => {
  return (
    <StyledButtonSubMenu
      variant="transparent"
      color="Gray/Gray 8"
      aria-label={label}
      className={className}
      onClick={() => handleClickToToggleSubPanel(panel)}
      $isSelected={panel === openedSub}
    >
      <ButtonContentSubMenu>
        {icon}
        <Text size="10px" fw={500}>
          {displayText}
        </Text>
        <ActiveBar className={panel === openedSub ? 'opened' : ''}></ActiveBar>
      </ButtonContentSubMenu>
    </StyledButtonSubMenu>
  );
};

export default BaseSubMenuButton;
