import { MAX_LENGTH_PUBLISH_SETTINGS } from '~/constants';

export const validateSlug = (slug: string) => {
  if (slug.length > MAX_LENGTH_PUBLISH_SETTINGS) {
    return 'URL too long: URL slug should be no longer than 80 characters';
  }

  if (!/^[a-zA-Z0-9-]+$/.test(slug)) {
    return 'Invalid characters: Only letters, numbers, and hyphens are allowed';
  }

  return null;
};

export const validateTitle = (title: string) => {
  if (title.length > MAX_LENGTH_PUBLISH_SETTINGS) {
    return 'Too long: Page title should be no longer than 80 characters';
  }

  if (!/^[a-zA-Z0-9- ]+$/.test(title)) {
    return 'Invalid Characters: Please avoid using special characters in the title';
  }

  return null;
};
