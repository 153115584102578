import { Collapse } from '@mantine/core';
import {
  IconBulb,
  IconIcons,
  IconPhoto,
  IconSearch,
  IconSection,
  IconTextSize,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import BaseSubMenuButton from '../BaseSubMenuButton/BaseSubMenuButton';
import { SubMenuContainer } from '../Toolbar.styles';
import { subPanelCollection } from '../useToolbarOperations';

import { MakeOpenSubPanelObj, PublishSubPanelTypes, SubPanelTypes } from '~/global.types';

interface AddElementMenuProps {
  opened: boolean;
  makeOpenSubPanel?: MakeOpenSubPanelObj;
}

const AddElementMenu = ({ opened, makeOpenSubPanel }: AddElementMenuProps) => {
  const [openedSub, setOpenedSub] = useState('');

  useEffect(() => {
    setOpenedSub(makeOpenSubPanel?.type || '');
  }, [makeOpenSubPanel]);

  const handleClickToToggleSubPanel = (panelName: SubPanelTypes | PublishSubPanelTypes) => {
    setOpenedSub(openedSub === panelName ? '' : panelName || '');
  };

  return (
    <Collapse in={opened} transitionDuration={100} transitionTimingFunction="linear">
      <SubMenuContainer style={{ borderRadius: '0px 4px 4px 4px' }} data-editor-sync-when-mousedown>
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Search"
          icon={<IconSearch size={16} />}
          className="d-none"
        />
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Elements"
          label="Add element"
          panel="add-element"
          icon={<IconIcons size={16} />}
        />
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Text"
          label="Add text"
          panel="add-text"
          icon={<IconTextSize size={16} />}
        />
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Images"
          label="Add image"
          panel="add-image"
          icon={<IconPhoto size={16} />}
        />
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Section"
          label="Add section"
          panel="add-section"
          icon={<IconSection size={16} />}
        />
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Inspiration"
          icon={<IconBulb size={16} />}
          className="d-none"
        />
      </SubMenuContainer>

      {Object.entries(subPanelCollection).map(([key, obj]) => {
        const Panel = obj.component;
        return (
          <Panel
            key={key}
            opened={openedSub === key}
            handleClickToToggleSubPanel={handleClickToToggleSubPanel}
            dataFromPostMessage={makeOpenSubPanel?.data}
            viewerDataFromPostMessage={makeOpenSubPanel?.viewerData}
          />
        );
      })}
    </Collapse>
  );
};

export default AddElementMenu;
