import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import styled from 'styled-components';
interface StyledButtonSubMenuProps extends ButtonProps {
  $isSelected: boolean;
}

export const StyledButton = styled(Button)`
  height: 48px;
  width: 64px;
  padding: 1px 4px;
  margin: 0 0 4px 0;
  border-radius: 4px;

  &:hover,
  &.panel-opened {
    background: #f1f3f5;
  }

  &.opened {
    height: 52px;
    border-radius: 4px 4px 0 0;
    margin: 0;
    background-color: #f1f3f5;
  }

  &.d-none {
    display: none;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin: 0;
`;

const _StyledButtonSubMenu = styled(Button)<{
  $isSelected: boolean;
  disabled: boolean;
}>`
  height: 48px;
  width: 64px;
  padding: 8px 4px 0px 4px;
  margin: 0;
  border-radius: 0px;
  border: none;
  color: ${({ $isSelected }) => ($isSelected ? '#5a1cec' : 'black')};

  &:hover {
    color: #5a1cec;
  }

  &.d-none {
    display: none;
  }
`;

// styled-components cannot correctly extract prop types from polymorphic components
// we must use createPolymorphicComponent function to redefine polymorphic component type
export const StyledButtonSubMenu = createPolymorphicComponent<'button', StyledButtonSubMenuProps>(
  _StyledButtonSubMenu,
);

export const ButtonContentSubMenu = styled.div`
  display: flex;
  width: fit-content;
  height: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin: 0;

  &.opened {
    color: #5a1cec;
  }
`;

export const ActiveBar = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 5px 5px 0px 0px;
  margin-top: 4px;

  &.opened {
    background-color: #5a1cec;
  }
`;

export const SubMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #f1f3f5;
  align-items: center;
  width: 401px;
  //The extra pixel is to align the container with the buttons of the toolbar
  //(needed because of border issues)
  height: 48px;
  border-radius: 4px;
`;
