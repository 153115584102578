// Just use in the new Publish UI but will replace the old one

import { Button, Flex, ScrollArea, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconConfetti, IconCopy, IconExternalLink } from '@tabler/icons-react';
import styled from 'styled-components';

import BasePanel from '../BasePanel/BasePanel';
import {
  FormDomainType,
  FormForNotContentType,
  FormType,
} from '../PublishSettings/PublishSettingsMenu';

import msg from '~/helpers/viewerInteractions/msg';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

const { VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME = '*.testing.upflowyexperience.com' } =
  import.meta.env || {};

export interface PublishConfirmationPanelProps {
  opened: boolean;
  onClickToClose: () => void;
  form: FormType;
  formForNotContent: FormForNotContentType;
  formDomain: FormDomainType;
}

const StyledText = styled(Text)`
  color: var(--m3-sys-light-primary);
  font-weight: 800;
  font-size: var(--m3-label-large-size);
`;

const StyledFlex = styled(Flex)`
  color: var(--m3-sys-light-primary);
  align-items: center;
  gap: 8px;
`;

const PublishConfirmationPanel = ({
  opened,
  onClickToClose,
  form,
  formDomain,
  formForNotContent,
}: PublishConfirmationPanelProps) => {
  const { pageSetId } = usePageSet();

  const live_url = `https://${VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME.replace(
    '*',
    formForNotContent.values.workspaceId,
  )}/${form.values.urlSlug}`;

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    notifications.show({
      color: 'green',
      message: 'Copied to clipboard!',
      autoClose: 3000,
    });
  };

  const handleCloseButton = () => {
    onClickToClose();
  };

  return (
    <BasePanel
      opened={opened}
      onClickToClose={handleCloseButton}
      label="Publish"
      title="Subdomain instructions"
      icon={
        <IconArrowLeft
          onClick={() =>
            msg({
              type: 'open-toolbar-panel',
              openToolbarPanel: 'publish-settings',
              openToolbarSubPanel: 'publish-go-live',
            })
          }
          cursor={'pointer'}
          aria-label='Back to "Publish Go Live" panel'
        />
      }
    >
      <ScrollArea type="never">
        <StyledFlex mt={20}>
          <IconConfetti size={26} />
          <StyledText>
            {pageSetId
              ? `Congratulations! Yours variants are published!`
              : 'Congratulations! Your page is published!'}
          </StyledText>
        </StyledFlex>

        <Flex align={'center'} gap={'xs'} mt={32}>
          <Text size="sm" style={{ wordBreak: 'break-all' }}>
            {live_url}
          </Text>
          <IconCopy
            size={20}
            cursor={'pointer'}
            onClick={() => handleCopyToClipboard(live_url)}
            aria-label="Copy live page URL"
          />
        </Flex>
        <Button variant="default" w="100%" mt={20} onClick={() => window.open(live_url, '_newtab')}>
          <IconExternalLink />
          <Text ml={8}>Live page</Text>
        </Button>

        {/* Display CNAME value if needed */}
        {!formDomain.values.error && (
          <>
            <Text size="sm" fw={500} mt={32}>
              Subdomain Instructions
            </Text>

            <Text size="xs">
              Before your page will be displayed on your designed subdomain you must first set your{' '}
              <span style={{ fontWeight: 700, color: '#495057' }}>CNAME</span> record with your DNS
              provider.
            </Text>
            <br></br>
            <Text size="xs">
              <a href="#">Click here for instructions</a> on how to set your{' '}
              <span style={{ fontWeight: 700, color: '#495057' }}>CNAME</span> record, and you will
              find your <span style={{ fontWeight: 700, color: '#495057' }}>CNAME</span> value
              below.
            </Text>

            <TextInput
              label="CNAME name"
              placeholder="pages"
              readOnly
              rightSection={<IconCopy size={16} style={{ cursor: 'pointer' }} />}
              rightSectionProps={{
                onClick: () => {
                  handleCopyToClipboard(formDomain.values.domain.split('.')[0]);
                },
              }}
              size="sm"
              mt="md"
              value={formDomain.values.domain.split('.')[0]}
            />

            <TextInput
              label="CNAME value"
              size="sm"
              readOnly
              rightSection={
                <IconCopy size={16} style={{ cursor: 'pointer' }} aria-label="Copy CNAME button" />
              }
              rightSectionProps={{
                onClick: () => {
                  handleCopyToClipboard(formDomain.values.cname);
                },
              }}
              mt="md"
              {...formDomain.getInputProps('cname')}
            />
          </>
        )}
      </ScrollArea>
    </BasePanel>
  );
};

export default PublishConfirmationPanel;
