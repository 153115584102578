import { awsAPIClient } from '~/helpers/ApiClient';
import { PageSetVariantStateObj } from '~/providers/PageSetProvider/types';

export interface CreatePageSetResponse {
  message: string;
  data: {
    definition: string;
    nanoId: string;
  };
}

export const createPageSet = async ({
  workspaceId,
  pageNanoId,
  variantStrategy,
}: {
  workspaceId: string;
  pageNanoId: string;
  variantStrategy: string;
}) => {
  return awsAPIClient.post<CreatePageSetResponse>('/create-page-set', {
    workspaceId,
    pageNanoId,
    variantStrategy,
  });
};

export const createPageVariants = async ({
  pageSetId,
  variantName,
  variantKeywords,
  slugPattern,
  titlePattern,
}: {
  pageSetId: string;
  variantName?: string;
  variantKeywords: string[];
  slugPattern?: string;
  titlePattern?: string;
}) => {
  if (!pageSetId || !variantKeywords)
    console.error('You need pageSetId, variantName and variantKeywords to create page variants.');

  return awsAPIClient.post<GetPageVariantsResponse>('/create-page-variants', {
    pageSetId,
    variantName,
    variantKeywords,
    slugPattern,
    titlePattern,
  });
};

export interface GetPageVariantData {
  name: string;
  nanoId: string;
  variantNodes?: Record<string, { textValue: string; type: string }>;
  lastPublishedDate?: number;
  definition: string;
  title: string;
  description?: string;
  workspaceId: string;
}
export interface GetPageVariantsResponse {
  count: number;
  data: GetPageVariantData[];
  variantStrategy: string;
  pageSetId: string;
}

export interface GetPageSetData {
  definition: string;
  state?: PageSetVariantStateObj;
  nanoId: string;
}

export interface UpdatePageSetData {
  definition: string;
  state?: PageSetVariantStateObj;
  variantNodeIds?: string[];
  nanoId: string;
}
export interface UpdatePageVariantData {
  message: string;
  data: {
    definition: string;
    title?: string;
    description?: string;
  };
}

export const getPageVariants = async ({ pageSetId }: { pageSetId: string }) => {
  return awsAPIClient.post<GetPageVariantsResponse>('/get-page-variants', {
    pageSetId,
  });
};

export const getVariant = async ({ nanoId }: { nanoId: string }) => {
  return awsAPIClient.post<GetPageVariantData>('/get-page-variant', {
    nanoId,
  });
};

export const getPageSet = async ({ nanoId }: { nanoId: string }) => {
  return awsAPIClient.post<GetPageSetData>('/get-page-set', {
    nanoId,
  });
};

export interface RegenerateVariantsResponse {
  message: string;
}

export const regenerateVariants = async ({
  pageId,
  pageSetId,
  pageVariantId,
}: {
  pageId: string;
  pageSetId?: string;
  pageVariantId?: string;
}) => {
  if (!pageId || (!pageSetId && !pageVariantId))
    console.log('You need pageId, and pageSetId or pageVariantId to regenerate page variants.');

  return awsAPIClient.post<RegenerateVariantsResponse>('/regenerate-variants', {
    pageId,
    pageSetId,
    pageVariantId,
  });
};

export const updatePageSet = async ({
  nanoId,
  state,
  variantNodeIds,
}: {
  nanoId: string;
  state?: PageSetVariantStateObj;
  variantNodeIds?: string[];
}) => {
  return awsAPIClient.put<UpdatePageSetData>('/update-page-set', {
    nanoId,
    state,
    variantNodeIds,
  });
};
export interface DeletePageVariantResponse {
  message: string;
}

export const deletePageVariant = async ({ nanoId }: { nanoId: string }) => {
  return awsAPIClient.post<DeletePageVariantResponse>('/delete-page-variant', {
    nanoId,
  });
};

export const updatePageVariant = async ({
  nanoId,
  definition,
  title,
  description,
}: {
  nanoId: string;
  definition?: string;
  title?: string;
  description?: string;
}) => {
  return awsAPIClient.put<UpdatePageVariantData>('/update-page-variant', {
    nanoId,
    definition,
    title,
    description,
  });
};
