import { VariantPublishMessageCollection, VariantPublishState } from '../types';
import useVariantWebSocket from '../useVariantWebsocket/useVariantWebsocket';

const useVariantPublishWebSocket = ({ pageSetId }: { pageSetId?: string }) => {
  return useVariantWebSocket<VariantPublishMessageCollection, VariantPublishState>({
    pageSetId,
    config: {
      actionType: 'PUBLISHED',
      storageKeySuffix: 'publish',
      processingState: 'PUBLISHING' as VariantPublishState,
    },
  });
};

export default useVariantPublishWebSocket;
