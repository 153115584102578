import { Flex, Stack, Title } from '@mantine/core';
import { IconCode, IconIcons } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';

import { generateStylesList, StylesList } from './generateStyle';

import BasePanel from '../BasePanel/BasePanel';
import { BrandToken, parseCSSVariables } from '../StylePanel/styleProcessing';

import { ToolbarPanelProps } from '~/global.types';
import { getStyleWithString } from '~/helpers/cssVariableProcessing/cssVariableProcessing';
import {
  addListenerForViewerDropzones,
  removeListenerForViewerDropzones,
} from '~/helpers/viewerInteractions/dropzoneEventHandling';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';

type DataItem = {
  tagName?: string;
  textValue?: string;
  '@target'?: string;
  '@href'?: string;
  object?: string;
  type?: string;
  style?: Record<string, string>;
  '@role'?: string;
};

interface AddElementProps {
  dataItem?: DataItem;
  userStyle?: Record<string, string>;
}

const DraggableElement = styled.div`
  & > * {
    margin: 0;
    pointer-events: none;
  }

  &:hover {
    box-shadow:
      0px 1px 3px 0px #0000002f,
      0px 4px 3.799999952316284px 0px #0000001a;
    opacity: 0.8;
  }
`;

const AddElement = ({ dataItem = {}, userStyle }: AddElementProps) => {
  const [{ opacity, cursor }, dragRef, dragPreview] = useDrag(
    () => ({
      type: 'add-page-element',
      item: dataItem,
      collect: (monitor) => {
        if (monitor.isDragging()) addListenerForViewerDropzones();
        return {
          opacity: monitor.isDragging() ? 0.5 : undefined,
          cursor: monitor.isDragging() ? 'grabbing' : 'grab',
        };
      },
      end: removeListenerForViewerDropzones,
    }),
    [dataItem],
  );

  const Tag = dataItem.tagName as React.ElementType;

  return (
    <>
      <DraggableElement
        ref={dragRef}
        style={{
          opacity,
          cursor,
          ...userStyle,
        }}
      >
        <Tag>Button</Tag>
      </DraggableElement>

      <DraggableElement
        ref={dragPreview}
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          zIndex: -1,
          ...userStyle,
          transform: 'translateY(-10px)',
        }}
      >
        <Tag>Button</Tag>
      </DraggableElement>
    </>
  );
};

const AddElementPanel = ({ opened, handleClickToToggleSubPanel }: ToolbarPanelProps) => {
  const [brandTokens, setBrandTokens] = useState<BrandToken[]>([]);
  const [stylesList, setStylesList] = useState<StylesList[]>([]);
  const { featureFlags } = useFeatureFlags();

  useEffect(() => {
    if (opened) {
      msg({ type: 'trigger-broadcast-page-data' });
    }
  }, [opened]);

  useViewerMessage(
    async ({ data }) => {
      if (data.type === 'broadcast-page-data' && opened) {
        const { head } = JSON.parse(data.pageData);
        const { textValue: rootStyleCSS } = head.find(getStyleWithString(':root'));
        setBrandTokens(parseCSSVariables(rootStyleCSS));
      }
      if (data.type === 'head-edited') {
        msg({ type: 'trigger-broadcast-page-data' });
      }
    },
    [opened],
  );

  useEffect(() => {
    setStylesList(generateStylesList(brandTokens));
  }, [brandTokens]);

  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('add-element')}
      label="Add element options"
      title="Elements"
      icon={<IconIcons />}
    >
      <Stack mt={24} gap={32}>
        <Stack gap={16}>
          <Title fw={600} size={16}>
            Buttons
          </Title>
          {stylesList.map((styleColorGroup, groupIndex) => (
            <div key={groupIndex}>
              <Flex gap={8} pt={1} pb={1}>
                {styleColorGroup.map((userStyle, index) => (
                  <AddElement
                    key={index}
                    dataItem={{
                      tagName: 'a',
                      textValue: 'Button',
                      '@role': 'button',
                      '@href': '',
                      object: 'primitive',
                      type: 'button',
                      '@target': '_blank',
                      style: userStyle[0],
                    }}
                    userStyle={{ ...userStyle[1] }}
                  />
                ))}
              </Flex>
            </div>
          ))}
        </Stack>
        {featureFlags.codeEmbedAccess?.enabled && (
          <Stack gap={16}>
            <Title fw={600} size={16}>
              Embed code
            </Title>
            <Flex
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '50%',
                height: '100px',
                borderRadius: '4px',
                border: '1px solid #f3f1ff',
                boxShadow: '0px 2px 4px 0px #d5d2f0',
              }}
            >
              <IconCode height={24} color="#5a1cec" />
            </Flex>
          </Stack>
        )}
      </Stack>
    </BasePanel>
  );
};

export default AddElementPanel;
