import { Box, Button, Divider, Modal, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';

import AddVariant from './AddVariant/AddVariant';
import DefineVariant from './DefineVariant/DefineVariant';
import DeleteVariant from './DeleteVariant/DeleteVariant';
import GeneratingVariantLightbox from './GeneratingVariantLightbox/GeneratingVariantLightbox';
import ManageVariant from './ManageVariant/ManageVariant';
import SyncVariantLightBox from './SyncVariantLightBox/SyncVariantLightBox';

import { IconRegenVariants } from '~/components/CustomIcons/IconRegenVariants';
import IconSyncVariants from '~/components/CustomIcons/IconSyncVariants';
import IconVariant from '~/components/CustomIcons/IconVariant';
import VariantDropdownButton from '~/components/VariantDropdownButton/VariantDropdownButton';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';

const VariantMenuExtension = () => {
  const {
    isLoading,
    pageSetId,
    selectedVariant,
    blueprintEdited,
    resetPageSetContext,
    regenerateAllPageVariants,
  } = usePageSet();
  const [openedAddVariantModal, setOpenedAddVariantModal] = useState(false);
  const [openedSyncVariantModal, setOpenedSyncVariantModal] = useState(false);
  const [openedDeleteVariantModal, setOpenedDeleteVariantModal] = useState(false);
  const [selectedVariantToDelete, setSelectedVariantToDelete] = useState<GetPageVariantData | null>(
    null,
  );

  useViewerMessage(
    ({ data }) => {
      if (data.type === 'show-modal' && data.modal === 'sync-variant') {
        setOpenedSyncVariantModal(true);
      }

      if (data.type === 'show-modal' && data.modal === 'delete-variant') {
        setSelectedVariantToDelete(selectedVariant || null);
        setOpenedDeleteVariantModal(true);
      }
    },
    [selectedVariant],
  );

  useEffect(() => () => resetPageSetContext(), []);

  const [openedModal, setOpenedModal] = useState(false);

  const handleClickToOpenModal = () => {
    setOpenedModal(true);
  };

  const handleCloseModal = () => {
    setOpenedModal(false);
  };

  const handleClickToOpenAddVariantModal = () => {
    setOpenedAddVariantModal(true);
  };

  const handleCloseAddVariantModal = () => {
    setOpenedAddVariantModal(false);
  };

  const handleClickToOpenSyncVariantModal = () => {
    setOpenedSyncVariantModal(true);
  };

  const handleCloseSyncVariantModal = () => {
    setOpenedSyncVariantModal(false);
  };

  const handleClickSyncVariantModal = () => {
    setOpenedSyncVariantModal(false);
    regenerateAllPageVariants();
  };

  const handleClickToOpenDeleteVariantModal = (variant: GetPageVariantData) => {
    setSelectedVariantToDelete(variant);
    setOpenedDeleteVariantModal(true);
  };

  const handleCloseDeleteVariantModal = () => {
    setSelectedVariantToDelete(null);
    setOpenedDeleteVariantModal(false);
  };

  const button =
    !isLoading && !pageSetId ? (
      <Tooltip
        multiline
        w={220}
        label="Click to generate a new variant
  based on this page's content"
        withArrow
        color="var(--mantine-color-gray-7)"
        p={8}
        zIndex={10000}
        ta="center"
      >
        <Button leftSection={<IconVariant />} radius={4} onClick={handleClickToOpenModal} h={40}>
          Create variant
        </Button>
      </Tooltip>
    ) : (
      <VariantDropdownButton
        isLoading={isLoading}
        selectedVariantName={selectedVariant?.name}
        popoverWidth={300}
      >
        <ManageVariant
          onOpenAddVariantModal={handleClickToOpenAddVariantModal}
          onOpenDeleteVariantModal={handleClickToOpenDeleteVariantModal}
        />
      </VariantDropdownButton>
    );

  const variantNodeEdits = Object.keys(blueprintEdited || {}).length;

  return (
    <>
      <Divider orientation="vertical" />
      <Box>{button}</Box>

      {pageSetId && (
        <>
          <Divider orientation="vertical" />
          <Tooltip
            label="Sync all edits"
            withArrow
            color="var(--mantine-color-gray-7)"
            p={8}
            zIndex={10000}
            disabled={!variantNodeEdits}
          >
            <Button
              leftSection={variantNodeEdits ? <IconSyncVariants /> : <IconRegenVariants />}
              onClick={handleClickToOpenSyncVariantModal}
              disabled={!variantNodeEdits}
              h={40}
            >
              {`${variantNodeEdits ? 'Sync ' : ''}${variantNodeEdits} edit${variantNodeEdits !== 1 ? 's' : ''}`}
            </Button>
          </Tooltip>
        </>
      )}

      <SyncVariantLightBox
        opened={openedSyncVariantModal}
        onClose={handleCloseSyncVariantModal}
        onSyncClick={handleClickSyncVariantModal}
      />

      <Modal
        opened={openedModal}
        onClose={handleCloseModal}
        title={<b style={{ fontSize: '1.5rem' }}>Create variant</b>}
        size={607}
        zIndex={99999}
        centered
        styles={{ content: { maxHeight: '95vh' } }}
      >
        <DefineVariant onClose={handleCloseModal} />
      </Modal>

      <GeneratingVariantLightbox />
      <Modal
        opened={openedAddVariantModal}
        onClose={handleCloseAddVariantModal}
        title={<b style={{ fontSize: '1.5rem' }}>Add more variants</b>}
        size="35%"
        zIndex={99999}
        centered
      >
        <AddVariant onClose={handleCloseAddVariantModal} />
      </Modal>

      <Modal
        opened={openedDeleteVariantModal}
        onClose={handleCloseDeleteVariantModal}
        title={
          <b style={{ fontSize: '1.2rem' }}>Delete Variant: {selectedVariantToDelete?.name}</b>
        }
        zIndex={99999}
        size="38%"
        radius="8px"
        centered
      >
        <DeleteVariant
          onClose={handleCloseDeleteVariantModal}
          selectedVariantToDelete={selectedVariantToDelete}
        />
      </Modal>
    </>
  );
};

export default VariantMenuExtension;
