import ShortUniqueId from 'short-unique-id';

import modifyPageData from '../modifyPageData/modifyPageData';

import { ContainsNode, PageJsonSnippetObj } from '~/global.types';
import { findAndChangePageData, findPageData } from '~/helpers/pageDataTraverse/pageDataTraverse';
import { StudioMessage } from '~/messages.types';

const { randomUUID } = new ShortUniqueId();

const appendObject = (
  updatedObject: PageJsonSnippetObj,
  move: boolean,
  addNode: ContainsNode,
  slot?: string,
  addNewCol?: number,
  addIntoCol?: number,
  addAfter?: string,
  addBefore?: string,
) => {
  let addSlot = slot;
  const { selector } = addNode;
  const newSelector = move ? selector : `elem-${randomUUID(8)}`;

  if (addNewCol) {
    addSlot = `column-${addNewCol}`;

    // Any columns need to shift up by 1 if a new column is inserted in between
    updatedObject.contains?.forEach((node) => {
      const nodeCol = parseInt(node['@slot']?.split('-')[1] || '0', 10);
      if (nodeCol >= addNewCol) {
        node['@slot'] = `column-${nodeCol + 1}`;
      }
    });

    // Shuffle the styles if a new column is added in between
    const styleKeys = Object.keys(updatedObject)
      .filter((key) => key.startsWith('style_for_.column-'))
      .sort((a, b) => parseInt(a.split('-')[1], 10) - parseInt(b.split('-')[1], 10));

    for (let i = styleKeys.length - 1; i >= 0; i--) {
      const currentColumnNumber = parseInt(styleKeys[i].split('-')[1], 10);
      if (currentColumnNumber >= addNewCol) {
        const currentStyleKey = `style_for_.column-${currentColumnNumber}`;
        const nextColumnNumber = currentColumnNumber + 1;
        const nextStyleKey = `style_for_.column-${nextColumnNumber}`;

        if (updatedObject[currentStyleKey]) {
          updatedObject[nextStyleKey] = updatedObject[currentStyleKey];
          delete updatedObject[currentStyleKey];
        }
      }
    }

    updatedObject.contains = [
      ...(updatedObject.contains || []),
      {
        ...addNode,
        '@slot': addSlot,
        selector: newSelector,
      },
    ];
  } else if (addIntoCol) {
    addSlot = `column-${addIntoCol}`;

    updatedObject.contains = [
      ...(updatedObject.contains || []),
      {
        ...addNode,
        '@slot': addSlot,
        selector: newSelector,
      },
    ];
  } else if (addBefore) {
    const { contains = [] } = updatedObject;
    updatedObject.contains = [
      ...contains.slice(
        0,
        contains.findIndex((item) => item.selector === addBefore),
      ),
      {
        ...addNode,
        '@slot': addSlot,
        selector: newSelector,
      },
      ...contains.slice(contains.findIndex((item) => item.selector === addBefore)),
    ];
  } else if (addAfter) {
    const { contains = [] } = updatedObject;
    updatedObject.contains = [
      ...contains.slice(0, contains.findIndex((item) => item.selector === addAfter) + 1),
      {
        ...addNode,
        '@slot': addSlot,
        selector: newSelector,
      },
      ...contains.slice(contains.findIndex((item) => item.selector === addAfter) + 1),
    ];
  }

  return {
    result: {
      ...updatedObject,
    },
    addedElementSelector: newSelector,
  };
};

const modifySelectorAndReturnUpdatedObject = (obj: PageJsonSnippetObj, elementSelector: string) => {
  const options = {
    id: elementSelector,
  };

  const modifySelectorCallback = (currentObject: PageJsonSnippetObj) => {
    currentObject.selector += '-del-move';
    return currentObject;
  };

  // Call the provided function with the callback to modify the object
  return findAndChangePageData(obj, options, modifySelectorCallback).fullUpdatedObject;
};

function addPageData(
  obj: PageJsonSnippetObj,
  message: StudioMessage[
    | 'AddPageElement'
    | 'MovePageElement'
    | 'AddImagePlaceholderWhileUploading'],
  showAddedElementSelector?: boolean,
) {
  const { messageData, addNewCol, addIntoCol, addAfter, addBefore } = message;
  if (!messageData.data.rootNodeHost) return obj;

  const recurringOptions = {
    id: messageData.data.rootNodeHost,
  };

  let move = false;
  let addNode: PageJsonSnippetObj | undefined;
  let modifiedDataObject = obj;

  // This move page element should have been its own function as it is obviously not just adding page data
  if (message.type === 'move-page-element') {
    move = true;
    addNode = findPageData(JSON.parse(JSON.stringify(obj)), message.selector);
    modifiedDataObject = modifySelectorAndReturnUpdatedObject(
      JSON.parse(JSON.stringify(obj)),
      message.selector,
    );
  } else {
    addNode = message.addNode;
  }

  if (!addNode) return {};

  let addedElementSelector: string | undefined;
  const resultAfterAdd = findAndChangePageData(
    modifiedDataObject,
    recurringOptions,
    (updatedObject) => {
      const data = appendObject(
        updatedObject,
        move,
        addNode,
        messageData.data.slot,
        addNewCol,
        addIntoCol,
        addAfter || messageData.data.elementSelector,
        addBefore,
      );

      addedElementSelector = data.addedElementSelector;

      return data.result;
    },
  );

  // Uh oh, this really shouldn't be here, this processing should be a part of messageHandling instead
  let result = resultAfterAdd.fullUpdatedObject;
  if (message.type === 'move-page-element') {
    result = modifyPageData(resultAfterAdd.fullUpdatedObject, {
      id: message.selector + '-del-move',
      toDelete: true,
    });
  }

  return showAddedElementSelector ? { result, addedElementSelector } : result;
}

export default addPageData;
