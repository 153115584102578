import DropzoneOverlay from './DropzoneOverlay/DropzoneOverlay';
import PageVariantEditedOverlay from './PageVariantEditedOverlay/PageVariantEditedOverlay';
import { FullScreenOverlay } from './PannableOverlays.styles';
import SelectElement from './SelectElement';
import { OverlayBoundRect } from './SetBoundForOverlayElements';
import ViewerHeadOverlay from './ViewerHeadOverlay/ViewerHeadOverlay';

import { ParsedLandingpageObject, ViewerData } from '~/global.types';
import {
  addListenerForViewerDropzones,
  removeListenerForViewerDropzones,
} from '~/helpers/viewerInteractions/dropzoneEventHandling';
import msg from '~/helpers/viewerInteractions/msg';
import { WheelForStudioPanZoomEvent } from '~/messages.types';

// eslint-disable-next-line react-refresh/only-export-components
export const initialBound: OverlayBoundRect<Partial<ViewerData>> = {
  x: -999,
  y: -999,
  width: 0,
  height: 0,
  data: {},
};

interface OverlaysProps {
  show: boolean;
  pageDetails?: Omit<ParsedLandingpageObject, 'content'>;
  enablePerformanceMode?: boolean;
  allowDevMode: {
    enabled: boolean;
  };
  allowPageSets?: {
    enabled: boolean;
  };
}

const PannableOverlays = ({
  show,
  pageDetails,
  enablePerformanceMode,
  allowDevMode,
  allowPageSets,
}: OverlaysProps) => {
  return (
    <FullScreenOverlay id="selection-overlay" style={{ display: show ? 'block' : 'none' }}>
      <ViewerHeadOverlay
        show={show}
        allowDevMode={allowDevMode}
        enablePerformanceMode={enablePerformanceMode}
      />
      <SelectElement show={show} allowDevMode={allowDevMode} />
      {allowPageSets?.enabled && <PageVariantEditedOverlay show={show} />}
      <DropzoneOverlay pageDetails={pageDetails} />
    </FullScreenOverlay>
  );
};

/**
 * For drag the image file to streamline upload and attachment to landing page
 */
window.addEventListener('dragenter', ({ dataTransfer }: DragEvent) => {
  if (dataTransfer?.types[0] === 'Files') addListenerForViewerDropzones();
});

window.addEventListener('drop', removeListenerForViewerDropzones);

/**
 * Mouse wheel / dual-finger-touch to pan trigger outside the viewer frame
 */
window.addEventListener(
  'wheel',
  (e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }

    const elem = e.target as HTMLDivElement;

    const partialWheelEvent: WheelForStudioPanZoomEvent = {
      deltaX: e.deltaX,
      deltaY: e.deltaY,
      clientX: e.clientX,
      clientY: e.clientY,
      ctrlKey: e.ctrlKey,
      from: 'overlay',
    };

    if (
      elem.dataset?.wheelScrollable ||
      elem.classList.contains('react-transform-wrapper') ||
      elem.classList.contains('react-transform-component') ||
      elem.id === 'iframe-overlay-blocker'
    ) {
      msg({ type: 'wheel-to-pan', partialWheelEvent });
    }
  },
  { passive: false },
);

export default PannableOverlays;
