import { VariantGenerationMessageCollection, VariantGenerationState } from '../types';
import useVariantWebSocket from '../useVariantWebsocket/useVariantWebsocket';

const useVariantGenerationWebSocket = ({ pageSetId }: { pageSetId?: string }) => {
  return useVariantWebSocket<VariantGenerationMessageCollection, VariantGenerationState>({
    pageSetId,
    config: {
      actionType: 'GENERATION',
      storageKeySuffix: 'generation',
      processingState: 'GENERATING' as VariantGenerationState,
    },
  });
};

export default useVariantGenerationWebSocket;
