import {
  ActionIcon,
  Combobox,
  Flex,
  Group,
  Menu,
  Paper,
  rem,
  ScrollArea,
  useCombobox,
} from '@mantine/core';
import { IconDotsVertical, IconLogout, IconPlus } from '@tabler/icons-react';
import Userfront from '@userfront/toolkit/react';
import { FocusEventHandler, SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NewWorkspaceModal from './NewWorkspaceModal';
import { PageNameField, ResizableTextInput } from './PrimaryNavigationMenu.styles';
import { ListItem } from './types';

import ProductLogo from '~/components/ProductLogo/ProductLogo.styles';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';

interface PrimaryNavigationMenuProps {
  list: ListItem[];
  selectedItem: ListItem;
  setSelectItemFromList: (id: string) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  contentEditable?: boolean;
  showDropdown: boolean;
  rightSectionComponent?: React.ReactElement | null;
}

const PrimaryNavigationMenu = ({
  list,
  onBlur,
  selectedItem,
  setSelectItemFromList,
  contentEditable = true,
  showDropdown,
  rightSectionComponent,
}: PrimaryNavigationMenuProps) => {
  const { featureFlags } = useFeatureFlags();
  const { manageWorkspace } = featureFlags;
  const [openWorkspaceModal, setOpenWorkspaceModal] = useState(false);
  const navigate = useNavigate();
  const combobox = useCombobox({
    scrollBehavior: 'smooth',
  });
  const handleClickLogout = (e: SyntheticEvent) => {
    e.preventDefault();
    //Clear the activeWorkspace from localStorage
    localStorage.removeItem('activeWorkspace');
    Userfront.logout();
  };

  return (
    <Flex align="stretch" gap={8} h={56}>
      <Paper shadow="xs" p={8} radius={8}>
        <ProductLogo onClick={() => navigate('/dashboard')} />
      </Paper>

      <Paper shadow="xs" px={11} radius={8}>
        <Flex align="center" gap={9} h="100%">
          <Menu
            zIndex={10001}
            shadow="md"
            position="bottom-start"
            width={200}
            styles={{
              item: { padding: '10px' },
            }}
          >
            <Group
              justify="space-between"
              style={{
                backgroundColor: '#fff',
                padding: '0 0 0 8px',
                borderRadius: '10px',
                gap: '5px',
              }}
            >
              <PageNameField style={{ flexGrow: 1 }}>
                {showDropdown ? (
                  <Combobox
                    width={'auto'}
                    zIndex={10001}
                    onOptionSubmit={(optionValue) => {
                      setSelectItemFromList(optionValue);
                      combobox.closeDropdown();
                    }}
                    store={combobox}
                  >
                    <Combobox.Target>
                      <Group>
                        <ResizableTextInput contentEditable={contentEditable} onBlur={onBlur}>
                          {selectedItem.name}
                        </ResizableTextInput>

                        <img
                          alt="open workspace list"
                          style={{ cursor: 'pointer' }}
                          src="/iconsarrow-drop-down-24px.svg"
                          onClick={() => combobox.toggleDropdown()}
                        />
                      </Group>
                    </Combobox.Target>

                    <Combobox.Dropdown>
                      <Combobox.Options>
                        <ScrollArea.Autosize mah={400} type="auto" scrollbarSize={4}>
                          {list.map(({ id, name }) => (
                            <Combobox.Option value={id} key={id}>
                              {name}
                            </Combobox.Option>
                          ))}
                        </ScrollArea.Autosize>
                      </Combobox.Options>
                    </Combobox.Dropdown>
                  </Combobox>
                ) : (
                  <ResizableTextInput contentEditable={contentEditable} onBlur={onBlur}>
                    {selectedItem.name}
                  </ResizableTextInput>
                )}
              </PageNameField>
              <Menu.Target>
                <ActionIcon variant="transparent" aria-label="Open primary menu">
                  <IconDotsVertical style={{ color: 'var(--color-black)' }} stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
            </Group>
            <Menu.Dropdown style={{ width: 'fit-content' }}>
              {manageWorkspace?.enabled && (
                <Menu.Item
                  leftSection={<IconPlus style={{ width: rem(14), height: rem(14) }} />}
                  onClick={() => setOpenWorkspaceModal(true)}
                >
                  Add new workspace
                </Menu.Item>
              )}
              <Menu.Item
                leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
                onClick={handleClickLogout}
              >
                Log out
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {openWorkspaceModal && (
            <NewWorkspaceModal
              name="New workspace"
              title="Create new workspace"
              close={() => setOpenWorkspaceModal(false)}
            />
          )}

          {rightSectionComponent}
        </Flex>
      </Paper>
    </Flex>
  );
};

export default PrimaryNavigationMenu;
