import seperateStyleFromInnerHtml from '~/helpers/viewerInteractions/seperateStyleFromInnerHtml';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';

const variantTextValueReplaceCallback =
  (viewer: HTMLIFrameElement, blueprintEdited?: Record<string, boolean> | null) =>
  ([key, obj]: [string, { textValue?: string } | undefined]) => {
    const variantEnabledElem = viewer.contentDocument?.querySelector(
      `[data-variant-node-id="${key}"]`,
    );
    const selector = variantEnabledElem && variantEnabledElem.getAttribute('data-selector');
    const blueprintHasBeenEdited = blueprintEdited ? blueprintEdited[selector || 'none'] : false;

    if (variantEnabledElem && obj && typeof obj === 'object' && !blueprintHasBeenEdited) {
      // If EditorJS is already appended, we don't need to seperate out the styles for innerHTML swap
      const foundEditorAppended = variantEnabledElem.querySelector('.upf-unit-edit');

      if (foundEditorAppended) {
        foundEditorAppended.innerHTML = obj.textValue || '';
      } else {
        if (!variantEnabledElem.hasAttribute('data-text-value-is-blank')) {
          const original = seperateStyleFromInnerHtml(variantEnabledElem.outerHTML);
          variantEnabledElem.innerHTML = original.styles + obj.textValue;
        }
      }
    }
  };

const updatePageContentWithVariant = (
  viewer: HTMLIFrameElement,
  variantNodes: GetPageVariantData['variantNodes'],
  blueprintEdited?: Record<string, boolean> | null,
) => {
  if (!variantNodes) return;

  Object.entries(variantNodes).forEach(variantTextValueReplaceCallback(viewer, blueprintEdited));
};

export default updatePageContentWithVariant;
