import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';

import { PageSetVariantStateObj, VariantGenerationState, VariantPublishState } from '../types';

import { LandingpageDetails } from '~/global.types';
import {
  getPageSet,
  GetPageVariantData,
  getPageVariants,
} from '~/services/PageSetServices/PageSetServices';
import { getAuthedUser, getMessages, getSubscriptions } from '~/services/UserServices';

type FirstPageLoadHandlingRequiredFunctions = {
  pageDetails: LandingpageDetails;
  shouldContinueVariantGeneration: (generationLocalStorageKey: string) => void;
  shouldContinueVariantPublish: (publishLocalStorageKey: string) => void;
  initVariantGeneration: (
    pageVariantList: string[] | null,
    lastGenerationResult?: Record<string, VariantGenerationState>,
    generatedVariants?: Record<string, boolean>,
  ) => void;
  initVariantPublish: (
    pageVariantList: string[] | null,
    lastPublishResult?: Record<string, VariantPublishState>,
    publishedVariants?: Record<string, boolean>,
  ) => void;
  updateVariantStateAndSwitchVariant: (
    pageSetNanoId: string,
    payload: PageSetVariantStateObj,
    switchVariant?: string,
  ) => void;
  setVariantList: (value: React.SetStateAction<GetPageVariantData[]>) => void;
  send: SendJsonMessage;
};

const webSocketProcessing = (
  localStorageTarget: string,
  relatedStateType: string,
  relatedVariantState: Record<string, string>,
  // "any" type is needed here to generalise the usage
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initCallBack: (...args: any[]) => void,
): boolean => {
  const stillNeedProcessing = localStorage.getItem(localStorageTarget);

  if (stillNeedProcessing) {
    const variantsProcessed: Record<string, boolean> = {};
    const variantStateNeedProcessingOnly: Record<string, string> = {};
    const needProcessingArray = stillNeedProcessing.split(',');
    const needProcessingSet = new Set(needProcessingArray);

    Object.keys(relatedVariantState).forEach((v) => {
      if (relatedVariantState[v] !== relatedStateType) {
        if (needProcessingSet.has(v)) variantsProcessed[v] = true;
        variantStateNeedProcessingOnly[v] = relatedVariantState[v];
      }
    });

    // show modal to user that variants are still publishing
    // subscription and message acknowledgement is only performed after a
    // complete publish and browser refresh
    initCallBack(needProcessingArray, variantStateNeedProcessingOnly, variantsProcessed);

    return true;
  }

  return false;
};

const firstPageLoadHandling = ({
  pageDetails,
  shouldContinueVariantGeneration,
  shouldContinueVariantPublish,
  initVariantGeneration,
  initVariantPublish,
  updateVariantStateAndSwitchVariant,
  setVariantList,
  send,
}: FirstPageLoadHandlingRequiredFunctions) => {
  const { pageSetId = '' } = pageDetails;
  const GENERATION_LOCALSTORAGE = `${pageSetId}-upflowy-variant-generation`;
  const PUBLISH_LOCALSTORAGE = `${pageSetId}-upflowy-variant-publish`;
  shouldContinueVariantGeneration(GENERATION_LOCALSTORAGE);
  shouldContinueVariantPublish(PUBLISH_LOCALSTORAGE);

  // We need to grab the variant "keyword" so that Manage Variant Panel'
  // can show. getPageSet in its current state only retrieves the list of variant ids
  Promise.all([
    getPageSet({ nanoId: pageSetId }),
    getPageVariants({ pageSetId }),
    getAuthedUser(),
    getMessages({}),
    getSubscriptions(),
  ]).then(([pageSetResult, pageVariantsResult, userData, messages, subscriptions]) => {
    const { state } = pageSetResult;
    const variantsData = pageVariantsResult.data;

    setVariantList(variantsData);

    const generatedVariantsState: Record<string, VariantGenerationState> =
      state?.generatedVariants || {};

    const publishedVariantsState: Record<string, VariantPublishState> =
      state?.publishedVariants || {};

    const variantIds = variantsData.map((variant) => variant?.nanoId);

    const currentUserId = userData?.userUuid;
    const messagesForTheVariants = messages?.data.filter((message) =>
      variantIds.includes(message.objectId),
    );

    messagesForTheVariants.forEach((message) => {
      if (Object.values(message).includes('VARIANT_GENERATION'))
        generatedVariantsState[message.objectId] = message.statusCode as VariantGenerationState;
      if (Object.values(message).includes('VARIANT_PUBLISHED'))
        publishedVariantsState[message.objectId] = message.statusCode as VariantPublishState;
    });

    updateVariantStateAndSwitchVariant(
      pageSetId,
      {
        ...state,
        ...(generatedVariantsState && { generatedVariants: generatedVariantsState }),
        ...(publishedVariantsState && { publishedVariants: publishedVariantsState }),
      },
      state?.editedVariant || variantsData[0]?.nanoId,
    );

    let hasWebSocketProcessing;

    if (!hasWebSocketProcessing)
      hasWebSocketProcessing = webSocketProcessing(
        GENERATION_LOCALSTORAGE,
        'GENERATING',
        generatedVariantsState,
        initVariantGeneration,
      );

    if (!hasWebSocketProcessing)
      hasWebSocketProcessing = webSocketProcessing(
        PUBLISH_LOCALSTORAGE,
        'PUBLISHING',
        publishedVariantsState,
        initVariantPublish,
      );

    if (!hasWebSocketProcessing) {
      messagesForTheVariants.forEach((message) => {
        const vals = Object.values(message);
        if (vals.includes('VARIANT_GENERATION') || vals.includes('VARIANT_PUBLISHED')) {
          send({
            action: 'acknowledge',
            userId: currentUserId,
            messageId: message.messageId,
          });
        }
      });

      // Most likely, if we extend the usage of Web Socket, we will need to define a pattern for this
      subscriptions.data.forEach(({ subscriptionId }) => {
        send({
          action: 'unsubscribe',
          subscriptionId,
        });
      });
    }
  });
};

export default firstPageLoadHandling;
