import { MAX_LENGTH_PUBLISH_SETTINGS, NAME_WORD_LIMIT, VARIANTS_KEYWORDS_LIMIT } from '~/constants';

const overMaxAmount = (value: string) => value.split(',').length > VARIANTS_KEYWORDS_LIMIT;

const findDupe = (value: string) =>
  Array.from(
    value
      .split(',')
      .map((item) => item.trim())
      .filter(
        (item, index, arr) => arr.indexOf(item) !== index && new Set(arr.slice(0, index)).has(item),
      ),
  );

export const validateKeywords = (value: string) => {
  if (overMaxAmount(value))
    return `You’ve reached the limit! Use ${VARIANTS_KEYWORDS_LIMIT} keywords or less.`;

  const dupe = findDupe(value);
  if (dupe.find((v) => v === '') !== undefined) return 'Empty name is not allowed';
  else if (dupe.length > 0) return `Duplicated keyword found - ${dupe.join(', ')}`;

  return null;
};

export const validateName = (value: string) => {
  if (value.length > NAME_WORD_LIMIT) return 'The variant name is too long';
  return null;
};

export const validateSlug = (slug: string) => {
  if (slug.length > MAX_LENGTH_PUBLISH_SETTINGS) {
    return `URL too long: URL slug should be no longer than ${MAX_LENGTH_PUBLISH_SETTINGS} characters`;
  }

  if (!slug.includes('@keyword')) {
    return 'Missing keyword: Please include @keyword in the URL slug';
  }

  let index = slug.indexOf('@');

  while (index !== -1) {
    const substringAfterAt = slug.substring(index + 1, index + 8);

    if (substringAfterAt !== 'keyword') {
      return 'Invalid characters: Only letters, numbers, and hyphens are allowed';
    }

    index = slug.indexOf('@', index + 1);
  }

  if (!/^[a-zA-Z0-9-@]+$/.test(slug)) {
    return 'Invalid characters: Only letters, numbers, and hyphens are allowed';
  }

  return null;
};

export const validateTitle = (title: string) => {
  if (title.length > MAX_LENGTH_PUBLISH_SETTINGS) {
    return `Too long: Page title should be no longer than ${MAX_LENGTH_PUBLISH_SETTINGS} characters`;
  }

  let index = title.indexOf('@');

  while (index !== -1) {
    const substringAfterAt = title.substring(index + 1, index + 8);

    if (substringAfterAt !== 'keyword') {
      return 'Invalid Characters: Please avoid using special characters in the title';
    }

    index = title.indexOf('@', index + 1);
  }

  if (!/^[a-zA-Z0-9-@ ]+$/.test(title)) {
    return 'Invalid Characters: Please avoid using special characters in the title';
  }

  return null;
};
