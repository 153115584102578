import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Divider,
  Group,
  ScrollArea,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import {
  IconCheck,
  IconLock,
  IconPlus,
  IconRefresh,
  IconSearch,
  IconTrash,
} from '@tabler/icons-react';

import IconSyncVariants from '~/components/CustomIcons/IconSyncVariants';
import msg from '~/helpers/viewerInteractions/msg';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';

interface ManageVariantProps {
  onOpenAddVariantModal: () => void;
  onOpenDeleteVariantModal: (variant: GetPageVariantData) => void;
}

type StatusVariantItem = 'generated' | 'failed' | 'unsynced' | 'locked';

const ManageVariant = ({ onOpenAddVariantModal, onOpenDeleteVariantModal }: ManageVariantProps) => {
  const {
    variantList,
    variantState,
    blueprintEdited,
    selectedVariant,
    getVariantView,
    regeneratePageVariant,
  } = usePageSet();

  const [filter, setFilter] = useInputState('');

  const filteredVariantList = variantList
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((variant: { name: string }) =>
      variant.name.toLowerCase().includes(filter.toLowerCase()),
    );

  const handleClickToGetVariant = (variantNanoId: string) => () => {
    msg({ type: 'reset-bound' });
    getVariantView(variantNanoId).then(() => {
      msg({
        type: 'open-toolbar-panel',
        openToolbarPanel: 'publish-settings',
        openToolbarSubPanel: 'publish-settings',
      });
    });
  };

  const handleClickToGetLockedVariant = () => {
    msg({ type: 'show-modal', modal: 'sync-variant' });
  };

  const statusComponents: Record<
    StatusVariantItem,
    JSX.Element | ((nanoId: string) => JSX.Element)
  > = {
    generated: <IconCheck size={20} color="var(--mantine-color-green-8)" />,
    failed: (nanoId: string) => (
      <>
        <Badge
          size="xs"
          px={10}
          py={2}
          color="var(--mantine-color-red-0)"
          fz={10}
          fw={600}
          c="var(--mantine-color-red-9)"
        >
          Page failed
        </Badge>
        <ActionIcon
          size={20}
          variant="transparent"
          color="black"
          onClick={() => regeneratePageVariant(nanoId)}
          aria-label="Regenerate variant"
        >
          <IconRefresh />
        </ActionIcon>
      </>
    ),
    unsynced: <IconSyncVariants size={15} />,
    locked: <IconLock size={18} />,
  };

  const VariantItem = ({
    variantName,
    handleClick,
    icons,
    disabled,
  }: {
    variantName: string;
    icons: React.ReactNode;
    handleClick: () => void;
    disabled?: boolean;
  }) => {
    return (
      <Group px={12} py={8} gap={8} justify="space-between">
        <UnstyledButton
          fz={14}
          onClick={handleClick}
          style={{ cursor: 'pointer', outlineOffset: '-2px' }}
          disabled={disabled}
          c={disabled ? 'var(--mantine-color-dimmed)' : 'var(--mantine-color-text)'}
        >
          {variantName}
        </UnstyledButton>

        <Group gap={8}>{icons}</Group>
      </Group>
    );
  };

  const renderVariantItems = (variant: GetPageVariantData, status: StatusVariantItem) => (
    <VariantItem
      key={variant.name}
      handleClick={
        status === 'locked'
          ? handleClickToGetLockedVariant
          : handleClickToGetVariant(variant.nanoId)
      }
      variantName={variant.name}
      disabled={status === 'failed'}
      icons={
        <>
          {typeof statusComponents[status] === 'function'
            ? statusComponents[status](variant.nanoId)
            : statusComponents[status]}
          <ActionIcon
            size={20}
            variant="transparent"
            color="black"
            onClick={() => onOpenDeleteVariantModal(variant)}
            aria-label="Delete variant"
          >
            <IconTrash />
          </ActionIcon>
        </>
      }
    />
  );

  const hasBluePrintEdited = blueprintEdited && Object.keys(blueprintEdited).length > 0;

  return (
    <>
      <Box p={4}>
        <TextInput
          value={filter}
          onChange={setFilter}
          placeholder="Filter variants"
          leftSection={<IconSearch size={18} />}
          variant="filled"
        />

        <Text px={12} pt={10} pb={4} fz={10} c="var(--mantine-color-gray-6)">
          Variants
        </Text>

        {hasBluePrintEdited ? (
          <>
            {selectedVariant && renderVariantItems(selectedVariant, 'unsynced')}
            {selectedVariant && <Divider py={4} />}

            <Text px={12} py={4} fz={10} c="var(--mantine-color-gray-6)">
              Sync edits to unlock all other variants
            </Text>

            <ScrollArea.Autosize mah={400} type="never">
              {filteredVariantList
                .filter((variant) => variant.nanoId !== selectedVariant?.nanoId)
                .map((variant) => renderVariantItems(variant, 'locked'))}
            </ScrollArea.Autosize>
          </>
        ) : (
          <ScrollArea.Autosize mah={400} type="never">
            {filteredVariantList.map((variant) =>
              renderVariantItems(
                variant,
                variantState.generatedVariants?.[variant.nanoId] === 'FAILURE'
                  ? 'failed'
                  : 'generated',
              ),
            )}
          </ScrollArea.Autosize>
        )}
      </Box>

      <Divider />

      <Box
        p={12}
        style={{
          boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)',
        }}
      >
        <Button
          w="100%"
          leftSection={<IconPlus />}
          onClick={onOpenAddVariantModal}
          disabled={hasBluePrintEdited}
        >
          More variants
        </Button>
      </Box>
    </>
  );
};

export default ManageVariant;
