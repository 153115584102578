import { Tooltip } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';

interface DeleteToolProps {
  viewerData: Partial<ViewerData>;
}

const handleClickToEmitInlineToolEvent = ({
  rootNodeHost,
  relatedColumnElementSelectors,
  elementSelector,
}: Partial<ViewerData>) => {
  const hasRelatedColumnElementSelectors =
    (relatedColumnElementSelectors?.length || 0) > 0 && relatedColumnElementSelectors;

  // This is to make sure the attribute generated by hydrator is cleaned up
  // so that the CSS placeholder does not apply to the deleted text unit
  document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
    if (viewer instanceof HTMLIFrameElement) {
      const deletedElem = viewer.contentDocument?.querySelector(
        `[data-selector=${elementSelector}]`,
      );
      deletedElem?.removeAttribute('data-text-value-is-blank');
    }
  });

  msg({
    type: 'delete-page-elements',
    delete:
      (elementSelector && [elementSelector]) ||
      hasRelatedColumnElementSelectors ||
      (rootNodeHost && [rootNodeHost]) ||
      [],
  });

  msg({ type: 'reset-bound' });
};

const DeleteTool = ({ viewerData }: DeleteToolProps) => {
  return (
    <Tooltip label="Delete" color="Gray" withArrow zIndex={1001}>
      <StyledActionIcon
        aria-label="delete-tool"
        variant="transparent"
        onClick={() => handleClickToEmitInlineToolEvent(viewerData)}
      >
        <IconTrash size={20} stroke={1.5} />
      </StyledActionIcon>
    </Tooltip>
  );
};

export default DeleteTool;
