import { useEffect, useReducer } from 'react';

import { variantWebSocketReducer } from './variantWebsocketReducer';

import {
  VariantConfig,
  VariantGenerationMessageCollection,
  VariantGenerationState,
  VariantPublishMessageCollection,
  VariantPublishState,
} from '../types';

import useCommonWebSocket from '~/hooks/useCommonWebSocket/useCommonWebSocket';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';
import { getAuthedUser } from '~/services/UserServices';

const useVariantWebSocket = <
  T extends VariantPublishMessageCollection | VariantGenerationMessageCollection,
  S extends VariantPublishState | VariantGenerationState,
>({
  pageSetId,
  config,
}: {
  pageSetId?: string;
  config: VariantConfig<S>;
}) => {
  let STORAGE_KEY = `${pageSetId}-upflowy-variant-${config.storageKeySuffix}`;
  const EVENT_TYPE = `VARIANT_${config.actionType}`;

  const { receive, send } = useCommonWebSocket<T>({
    filter: ({ data }) => data.includes(EVENT_TYPE),
  });

  const [state, dispatch] = useReducer(variantWebSocketReducer, {
    isProcessing: false,
    processed: null,
    toBeProcessedCount: 0,
  });

  const prepVariantActionBeforeInit = () => {
    dispatch({ type: 'prepare-to-process' });
  };

  const initVariantAction = (
    pageVariantList: string[] | null,
    lastActionResult?: Record<string, S>,
    processedVariants?: Record<string, boolean>,
    newPageSetId?: string,
  ) => {
    if (pageVariantList) {
      if (pageSetId || newPageSetId) {
        STORAGE_KEY = `${newPageSetId || pageSetId}-upflowy-variant-${config.storageKeySuffix}`;
        localStorage.setItem(STORAGE_KEY, pageVariantList.join(','));
      }
      dispatch({
        type: 'init-process',
        payload: { pageVariantList, processedVariants, lastActionResult },
      });
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  };

  const shouldContinueVariantAction = (storageKey: string) => {
    const stillProcessing = localStorage.getItem(storageKey);
    if (stillProcessing) {
      dispatch({
        type: 'init-process',
        payload: { pageVariantList: stillProcessing.split(',') },
      });
    }
  };

  const subscribeVariantMessages = async (pageVariantList: GetPageVariantData[]) => {
    const userData = await getAuthedUser();

    if (userData) {
      pageVariantList.forEach(({ nanoId }) => {
        send({
          userId: userData.userUuid,
          objectId: nanoId,
          action: 'subscribe',
          eventType: EVENT_TYPE,
        });
      });
    }
  };

  const abruptResetDueToRouteChange = () => {
    dispatch({ type: 'reset-state' });
  };

  const handleReceiveMessage = () => {
    if (receive && localStorage.getItem(STORAGE_KEY)) {
      if ('statusCode' in receive && ['SUCCESS', 'FAILURE'].includes(receive.statusCode)) {
        dispatch({
          type: 'mark-processed',
          objectId: receive.objectId,
          statusCode: receive.statusCode,
        });
      }
    }
  };

  const handleEndProcess = () => {
    if (state.processed) {
      const processedValues = Object.values(state.processed);
      const allProcessed = processedValues.every((val) => val === true);

      if (processedValues.length > 0 && allProcessed) {
        localStorage.removeItem(STORAGE_KEY);
        setTimeout(() => {
          dispatch({ type: 'end-process' });
        }, 1500);
      }
    }
  };

  useEffect(handleReceiveMessage, [receive]);
  useEffect(handleEndProcess, [state.processed]);

  return {
    processed: state.processed,
    lastActionCompleteTimestamp: state.lastActionCompleteTimestamp,
    lastActionResult: state.lastActionResult,
    toBeProcessedCount: state.toBeProcessedCount,
    prepVariantActionBeforeInit,
    initVariantAction,
    shouldContinueVariantAction,
    subscribeVariantMessages,
    abruptResetDueToRouteChange,
  };
};

export default useVariantWebSocket;
